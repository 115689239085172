import { useState } from 'react';
import { Dialog } from '@mui/material';
import { IconX } from '@tabler/icons-react';
import PropTypes from 'prop-types';

import { useCampaigns } from '../../store/campaigns/hooks';
import Button from '../buttons/Button';
import Input from '../inputs/Input';
import IconButton from '../buttons/IconButton';
import { campaignTypeLabels } from '../../core/constants';

const ChangeCampaignName = ({ open, onClose, onConfirm, loading }) => {
  const { campaignDetails } = useCampaigns();
  const [name, setName] = useState(campaignDetails.name);

  const handleClose = () => {
    if (loading) return;
    onClose();
  };

  const handleConfirm = () => {
    onConfirm(name);
  };

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      sx={{ '.MuiPaper-root': { padding: 0, margin: 0, borderRadius: '12px', width: '100%' } }}
    >
      <div className="space-y-6 p-6">
        <div className="relative flex">
          <h3 className="text-h6 font-semibold">Change {campaignTypeLabels[campaignDetails.type]} Name</h3>

          <IconButton
            Icon={<IconX size={20} className="text-gray-400" />}
            srOnly="Close"
            onClick={handleClose}
            color="transparent"
            className="absolute -right-1 -top-1 !p-1"
            disabled={loading}
          />
        </div>

        <Input
          label="New Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Campaign Name"
          required
          size="xl"
        />

        <div className="flex justify-end space-x-3">
          <Button title="Cancel" onClick={onClose} disabled={loading} />

          <Button title="Confirm" color="primary" onClick={handleConfirm} loading={loading} disabled={loading} />
        </div>
      </div>
    </Dialog>
  );
};

ChangeCampaignName.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  closeDisabled: PropTypes.bool,
  loading: PropTypes.bool,
};

ChangeCampaignName.defaultProps = {
  closeDisabled: false,
  loading: false,
};

export default ChangeCampaignName;
