import { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { useForm, yupResolver } from '@mantine/form';
import * as yup from 'yup';
import axios from 'axios';
import { IconWand } from '@tabler/icons-react';
import * as Sentry from '@sentry/react';
import { useIsMounted } from 'usehooks-ts';
import { Tooltip } from '@mui/material';

import { Button, Loader, Textarea } from 'ui';

import { useCampaigns } from '../../../store/campaigns/hooks';
import { campaignType, DEBOUNCE_DELAY_TIME_MS } from '../../../core/constants';
import { getImageUploadUrl, getTemplate, uploadImage } from '../../../api';
import appSettings from '../../../app-settings';
import ImagesInput from '../../inputs/ImagesInput';

const ARTIFICIAL_INTELLIGENCE_URL = `${appSettings.baseUrl}/artificial-intelligence/completions`;

// eslint-disable-next-line react-refresh/only-export-components
export const postContentFormValidation = yup.object({
  content: yup.string().required('Post content is required.').min(2, 'Content is invalid.'),
});

const PostContentForm = ({ disabled }) => {
  const [loading, setLoading] = useState(false);
  const [, setError] = useState(false);
  const { campaignDetails, campaignTemplate, setCampaignTemplate, updateCampaignDetails } = useCampaigns();
  const form = useForm({
    initialValues: {
      content: campaignDetails.socialMediaConfig?.content || '',
    },
    validate: yupResolver(postContentFormValidation),
    validateInputOnChange: true,
  });
  const isMounted = useIsMounted();

  const debouncedUpdateCampaignDetails = useMemo(
    () => debounce(updateCampaignDetails, DEBOUNCE_DELAY_TIME_MS),
    [updateCampaignDetails],
  );

  const handleMessageEdit = (e) => {
    const content = e.target.value;
    form.setFieldValue('content', content);
    debouncedUpdateCampaignDetails({ socialMediaConfig: { content } });
  };

  const getCampaignTemplate = useCallback(
    async (templateId, version = 1) => {
      setError(false);
      setLoading(true);
      try {
        const templateRes = await getTemplate(templateId, version);
        const template = templateRes.data;
        setCampaignTemplate(template);
      } catch (err) {
        Sentry.captureException(err);
        if (isMounted()) setError(err?.message || true);
      } finally {
        if (isMounted()) setLoading(false);
      }
    },
    [isMounted, setCampaignTemplate],
  );

  useEffect(() => {
    if (campaignDetails.template.templateId && campaignDetails.template.templateId !== campaignTemplate.templateId) {
      getCampaignTemplate(campaignDetails.template.templateId, campaignDetails.template.version);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generate = useCallback(async () => {
    setLoading(true);

    try {
      const prompt = `
      Adapt the following email to use as a social media post.
      Don't use any rich text formatting like markdown or HTML.
      Place any link URLs at the end of the post on a dedicated line.
      Find any images that aren't logos and add them to the images array.
      Return only a JSON object (with no wrapping text or formatting markdown) with the following keys:
      - content: The adapted email content
      - images: An array of image URLs
      Subject: ${campaignDetails.emailConfig.subject}
      Preview Text: ${campaignDetails.emailConfig.previewText}
      HTML email content:
      \`\`\`
      ${campaignTemplate.html}
      \`\`\`
    `;

      const result = await axios.post(ARTIFICIAL_INTELLIGENCE_URL, { prompt });
      const { completion } = result.data;
      const { content, images } = JSON.parse(completion);
      handleMessageEdit({ target: { value: content } });
      updateCampaignDetails({
        socialMediaConfig: {
          imageUrls: images,
        },
      });
    } catch (e) {
      setError(e?.message || true);
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignDetails, campaignTemplate]);

  const handleImagesEdit = async (files) => {
    const { fields, url } = await getImageUploadUrl();
    const results = await Promise.allSettled(files.map(async (file) => uploadImage(url, fields, file)));

    const successfulUploads = results
      .filter((result) => result.status === 'fulfilled')
      .map((result) => `${url}${fields.key.replace('${filename}', result.value.name)}`);
    const failedUploads = results.filter((result) => result.status === 'rejected');

    updateCampaignDetails({
      socialMediaConfig: {
        imageUrls: [...(campaignDetails.socialMediaConfig?.imageUrls || []), ...successfulUploads],
      },
    });
    form.setFieldError(
      'images',
      failedUploads.map((result) => {
        Sentry.captureException(result.reason.error);
        return `${result.reason.file.name} - Failed to upload`;
      }),
    );
  };

  const handleDelete = (url) => {
    updateCampaignDetails({
      socialMediaConfig: {
        imageUrls: campaignDetails.socialMediaConfig?.imageUrls.filter((image) => image !== url),
      },
    });
  };

  return (
    <form className="relative space-y-6">
      <div className="space-y-4">
        {campaignDetails.type === campaignType.single && campaignDetails.template.templateId && (
          <div className="flex items-center space-x-2">
            <Tooltip
              placement="right"
              title="We'll automatically adapt your email content and images to a format more suitable for a social media post."
            >
              <div>
                <Button
                  title="Adapt Email to Social Post"
                  onClick={generate}
                  LeftIcon={IconWand}
                  disabled={disabled || loading}
                />
              </div>
            </Tooltip>
          </div>
        )}
        <Textarea
          id="message"
          label="Post Content"
          value={form.values.content}
          onChange={handleMessageEdit}
          placeholder="Insert Post Content"
          required
          disabled={disabled || loading}
          error={!!form.errors.content}
          helperText={form.errors.content || ''}
          size="lg"
          inputClassName="field-sizing-content min-h-40"
        />
        <ImagesInput
          name="Logo"
          onChange={handleImagesEdit}
          onDelete={handleDelete}
          errors={form.errors.images}
          setErrors={(err) => form.setFieldError('images', err)}
          imageUrls={campaignDetails.socialMediaConfig?.imageUrls || []}
          disabled={disabled || loading}
        />
      </div>
      {loading && <Loader />}
    </form>
  );
};

PostContentForm.propTypes = {
  disabled: PropTypes.bool,
};

PostContentForm.defaultProps = {
  disabled: false,
};

export default PostContentForm;
