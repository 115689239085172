import { Dialog } from '@mui/material';
import { IconX, IconExclamationCircle } from '@tabler/icons-react';
import PropTypes from 'prop-types';

import { Button, CampaignAudience, CampaignPreview, IconButton, InfoRow, SocialPostPreview } from 'ui';
import { formatDate } from 'common/utils';

import { useCampaigns } from '../../store/campaigns/hooks';
import { campaignType } from '../../core/constants';
import { useTags } from '../../store/tags/hook';
import { useSegments } from '../../store/segments/hook';
import { getAudienceSizeCount } from '../../api';

const ConfirmLaunchModal = ({ open, onClose, onConfirm, message }) => {
  const { campaignDetails, campaignTemplate } = useCampaigns();
  const { tags, loading: loadingTags } = useTags();
  const { segments, loading: loadingSegments } = useSegments();

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={onClose}
      sx={{
        '.MuiPaper-root': {
          padding: 0,
          borderRadius: '12px',
          width: '100%',
          overflow: 'hidden',
        },
      }}
    >
      <div className="flex flex-col space-y-6 overflow-y-hidden p-6">
        <div className="relative flex">
          <div className="flex items-center space-x-3">
            <IconExclamationCircle size={20} />
            <h3 className="text-h6 font-semibold">
              {campaignDetails.type === campaignType.single ? 'Launch Campaign: ' : 'Schedule Post: '}
              {campaignDetails.name}
            </h3>
          </div>

          <IconButton
            Icon={<IconX size={20} className="text-gray-400" />}
            srOnly="Close"
            onClick={onClose}
            color="transparent"
            className="absolute -right-1 -top-1 !p-1"
          />
        </div>

        <div className="flex flex-auto gap-8 overflow-y-hidden">
          <div className="w-5/12 space-y-4">
            <div className="rounded-lg bg-primary-50 px-3 py-2">
              <p className="whitespace-pre-wrap text-base">
                {message ||
                  `${campaignDetails.type === campaignType.single ? 'Launched campaigns ' : 'Scheduled posts'} cannot be edited and can only be cancelled.`}
              </p>
            </div>

            {campaignDetails.type === campaignType.single ? (
              <>
                <CampaignAudience
                  campaign={campaignDetails}
                  tags={tags}
                  segments={segments}
                  getAudienceCount={getAudienceSizeCount}
                  loading={loadingTags || loadingSegments}
                />
                <InfoRow
                  primaryLabel="From"
                  secondaryLabel="Sender name"
                  value={campaignDetails.emailConfig.senderName}
                />
                <InfoRow
                  primaryLabel=""
                  secondaryLabel="Sender email"
                  value={campaignDetails.emailConfig.senderEmail}
                />
                <InfoRow
                  primaryLabel="Subject"
                  secondaryLabel="Subject line"
                  value={campaignDetails.emailConfig.subject}
                />
                <InfoRow
                  primaryLabel=""
                  secondaryLabel="Preview text"
                  value={campaignDetails.emailConfig.previewText}
                />
              </>
            ) : (
              <InfoRow
                primaryLabel="Networks"
                secondaryLabel=""
                value={
                  <>
                    {campaignDetails.socialMediaConfig?.targets.map((target) => (
                      <img
                        src={`/integrations/${target.type}-logo.png`}
                        className="size-[16px]"
                        alt={`${target.type} logo`}
                        key={target.id}
                      />
                    ))}
                  </>
                }
              />
            )}
            <InfoRow
              primaryLabel={campaignDetails.type === campaignType.single ? 'Send Time' : 'Post Time'}
              secondaryLabel="Date & time"
              value={
                campaignDetails.sendTime > new Date().toISOString()
                  ? formatDate(new Date(campaignDetails.sendTime).toISOString())
                  : 'Immediately'
              }
            />
          </div>
          <div className="w-7/12 overflow-y-hidden">
            {campaignDetails.type === campaignType.single && (
              <div className="max-h-full overflow-y-scroll border-2 border-gray-50 py-3">
                <CampaignPreview html={campaignTemplate.html} />
              </div>
            )}
            {campaignDetails.type === campaignType.socialMedia &&
              campaignDetails.socialMediaConfig?.targets.map((target) => {
                return (
                  <SocialPostPreview
                    key={target.id}
                    target={target}
                    content={campaignDetails.socialMediaConfig?.content}
                    imageUrls={campaignDetails.socialMediaConfig?.imageUrls}
                    className="w-96"
                  />
                );
              })}
          </div>
        </div>

        <div className="flex justify-end space-x-3">
          <Button title="Cancel" onClick={onClose} />

          <Button
            title={campaignDetails.type === campaignType.single ? 'Launch Campaign' : 'Schedule Post'}
            color="primary"
            onClick={onConfirm}
          />
        </div>
      </div>
    </Dialog>
  );
};

ConfirmLaunchModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  message: PropTypes.string,
};

export default ConfirmLaunchModal;
