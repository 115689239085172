import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { IconPlus } from '@tabler/icons-react';
import { Button } from 'ui';

import BarChartCard from '../components/dashboard/BarChartCard';
import AreaChartCard from '../components/dashboard/AreaChartCard';
import { useDashboard } from '../store/dashboard/hooks';
import { useReports } from '../store/reports/hooks.js';
import Loader from '../components/loader/Loader';
import AlertMessage from '../components/alerts/AlertMessage';
import {
  formatDonationsAmountSpread,
  formatDonationsByDay,
  formatDonationsByHour,
  getDonationAmountStats,
  getDonationCountStats,
  getEmailReportStats,
  getTodayDonationStats,
} from '../core/utils';
import SelectCampaign from '../components/dashboard/SelectCampaign';
import DonationHistory from '../components/dashboard/DonationHistory';
import CustomDonationSpreadXAxisTick from '../components/dashboard/CustomDonationSpreadXAxisTick';
import DonorsWidget from '../components/dashboard/DonorsWidget';
import StatsWidget from '../components/dashboard/StatsWidget';
import { useCampaigns } from '../store/campaigns/hooks';
import DateRangePicker from '../components/dashboard/DateRangePicker';
import RecommendedTasksWidget from '../components/dashboard/RecommendedTasksWidget';
import { getInvoices as getInvoicesApi } from '../api';
import { useAccount } from '../store/account/hooks';
import { campaignType, USER_ROLES } from '../core/constants';

const Dashboard = () => {
  const {
    loading: dashboardLoading,
    error: dashboardError,
    donations,
    startDate,
    endDate,
    reports,
    setError: setDashboardError,
    getDonationsData,
  } = useDashboard();
  const { error: reportsError, setError: setReportsError, getReports } = useReports();
  const { resetCampaignDetails, resetCampaignTemplate, updateCampaignDetails } = useCampaigns();
  const navigate = useNavigate();
  const { account } = useAccount();
  const [invoiceStatus, setInvoiceStatus] = useState('');

  const getInvoices = useCallback(async () => {
    if (!account?.subscription || account.userRole !== USER_ROLES.administrator) return;

    try {
      const invoices = await getInvoicesApi();
      const hasOpenInvoices = invoices?.some((invoice) => invoice.status === 'open');
      const hasPastDueInvoices = invoices?.some(
        (invoice) => invoice.status === 'open' && invoice.due_date * 1000 < Date.now(),
      );

      if (hasPastDueInvoices) {
        setInvoiceStatus('past_due');
      } else if (hasOpenInvoices) {
        setInvoiceStatus('open');
      } else {
        setInvoiceStatus('');
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  }, [account]);

  useEffect(() => {
    getDonationsData();
    getReports();
    getInvoices();
  }, [getDonationsData, getReports, getInvoices]);

  const toggleError = () => {
    if (dashboardError) setDashboardError(false);
    if (reportsError) setReportsError(false);
  };

  const goToNewCampaign = () => {
    resetCampaignDetails();
    resetCampaignTemplate();
    updateCampaignDetails({ type: campaignType.single });
    navigate('/new-campaign');
  };

  const donationAmountStats = useMemo(
    () => getDonationAmountStats(donations, startDate, endDate),
    [donations, startDate, endDate],
  );

  const todayDonationStats = useMemo(() => getTodayDonationStats(donations), [donations]);

  const donationCountStats = useMemo(
    () => getDonationCountStats(donations, startDate, endDate),
    [donations, startDate, endDate],
  );

  const emailReportStats = useMemo(
    () => getEmailReportStats(reports, startDate, endDate),
    [reports, startDate, endDate],
  );

  const donationsByHour = useMemo(() => formatDonationsByHour(donations), [donations]);

  const donationsAmountSpread = useMemo(() => formatDonationsAmountSpread(donations), [donations]);

  const donationsByDay = useMemo(() => formatDonationsByDay(donations), [donations]);

  const error = dashboardError || reportsError;

  return (
    <>
      <div className="flex min-w-[1110px] max-w-7xl flex-col space-y-6">
        {!!invoiceStatus && (
          <div
            className={`rounded border px-6 py-2.5 text-center text-base ${invoiceStatus === 'past_due' ? 'border-red-200 bg-red-50' : 'border-primary-200 bg-primary-50'}`}
          >
            Your account has unpaid invoices. Please review on the{' '}
            <Link
              to="/subscription"
              className={`font-bold ${invoiceStatus === 'past_due' ? 'text-red-700' : 'text-primary-700'}`}
            >
              Subscription
            </Link>{' '}
            page.
          </div>
        )}
        <div className="flex flex-row justify-between">
          <SelectCampaign />

          <div className="flex items-end space-x-3">
            <DateRangePicker />

            <Button title="New Campaign" color="primary" LeftIcon={IconPlus} onClick={goToNewCampaign} />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-2 grid grid-cols-2 gap-4">
            <StatsWidget
              title={donationAmountStats.title}
              totalAmount={donationAmountStats.totalAmount}
              trendDirection={donationAmountStats.trendDirection}
              trendAmount={donationAmountStats.trendAmount}
              trendLabel={donationAmountStats.trendLabel}
              dataSections={donationAmountStats.dataSections}
            />

            <StatsWidget
              title={todayDonationStats.title}
              totalAmount={todayDonationStats.totalAmount}
              trendDirection={todayDonationStats.trendDirection}
              trendAmount={todayDonationStats.trendAmount}
              trendLabel={todayDonationStats.trendLabel}
              dataSections={todayDonationStats.dataSections}
            />

            <StatsWidget
              title={donationCountStats.title}
              totalAmount={donationCountStats.totalAmount}
              trendDirection={donationCountStats.trendDirection}
              trendAmount={donationCountStats.trendAmount}
              trendLabel={donationCountStats.trendLabel}
              dataSections={donationCountStats.dataSections}
            />

            <StatsWidget
              title={emailReportStats.title}
              totalAmount={emailReportStats.totalAmount}
              trendDirection={emailReportStats.trendDirection}
              trendAmount={emailReportStats.trendAmount}
              trendLabel={emailReportStats.trendLabel}
              dataSections={emailReportStats.dataSections}
            />
          </div>

          <RecommendedTasksWidget />
        </div>
        <div className="grid grid-cols-3 gap-4">
          <div className="relative col-span-2">
            <AreaChartCard />
            {dashboardLoading && <Loader />}
          </div>

          <DonorsWidget />
        </div>
        <div className="grid grid-cols-3 gap-4">
          <div className="relative">
            <BarChartCard
              name="Donation Amount Spread"
              data={donationsAmountSpread}
              xDataKey="amountCategory"
              XAxisTick={CustomDonationSpreadXAxisTick}
            />
            {dashboardLoading && <Loader />}
          </div>
          <div className="relative">
            <BarChartCard
              name="Donations By Hour"
              data={donationsByHour}
              xDataKey="hour"
              XAxisTicks={['4 AM', '8 AM', '12 PM', '4 PM', '8 PM']}
            />
            {dashboardLoading && <Loader />}
          </div>
          <div className="relative">
            <BarChartCard name="Donations By Day" data={donationsByDay} xDataKey="day" interval={0} />
            {dashboardLoading && <Loader />}
          </div>
        </div>
        <DonationHistory />
      </div>

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        onClose={toggleError}
        severity="error"
      />
    </>
  );
};

export default Dashboard;
