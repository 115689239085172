import axios from 'axios';

import appSettings from '../app-settings';

const INTEGRATIONS_URL = `${appSettings.baseUrl}/integrations`;

export const getIntegrations = async () => {
  const response = await axios.get(INTEGRATIONS_URL);
  return response.data;
};

export const createIntegration = async (payload) => {
  const response = await axios.post(INTEGRATIONS_URL, payload);
  return response.data;
};

export const deleteIntegration = async (integrationId) => {
  // integrationId contains a # character, so we need to encode it
  const response = await axios.delete(`${INTEGRATIONS_URL}/${encodeURIComponent(integrationId)}`);
  return response.data;
};
