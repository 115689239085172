import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { getNetworkError } from 'common/utils';
import { CAMPAIGN_SEND_STATUS } from 'common/constants';
import { ConfirmationModal, Spinner, SwitchInput } from 'ui';
import { useFlags } from 'launchdarkly-react-client-sdk';

import appSettings from '../../app-settings';
import { useCampaigns } from '../../store/campaigns/hooks';
import { restoreSuggestedCampaign } from '../../api/campaigns';
import ChangeCampaignName from './ChangeCampaignName';
import ContentFormCard from './FormCards/ContentFormCard';
import FormCard from './FormCards/FormCard';
import FromForm, { fromFormValidationSchema } from './Forms/FromForm';
import OverviewForm from './Forms/OverviewForm';
import SubjectForm, { subjectFormValidation } from './Forms/SubjectForm';
import TimeForm, { timeFormValidation } from './Forms/TimeForm';
import ToForm from './Forms/ToForm';
import PostContentForm from './Forms/PostContentForm';
import PostNetworksForm from './Forms/PostNetworksForm';
import { useSegments } from '../../store/segments/hook';
import { useTags } from '../../store/tags/hook';
import { campaignType } from '../../core/constants';

const CAMPAIGNS_URL = `${appSettings.baseUrl}/campaigns`;

const expandComponents = {
  to: 'to',
  from: 'from',
  subject: 'subject',
  time: 'time',
  template: 'template',
  postNetworks: 'postNetworks',
  postContent: 'postContent',
};

const CampaignForm = () => {
  const { campaignDetails, updateCampaignDetails, resetCampaignTemplate, setError, setSuccess } = useCampaigns();
  const { loading: loadingSegments } = useSegments();
  const { loading: loadingTags } = useTags();
  const { campaignId } = useParams();
  const [nameOpen, setNameOpen] = useState(!campaignId && !campaignDetails.campaignId);
  const [activeExpanded, setActiveExpanded] = useState();
  const [loading, setLoading] = useState(false);
  const [restoring, setRestoring] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const navigate = useNavigate();
  const { socialMediaPosts } = useFlags();

  const setExpandedComponent = (component) => {
    setActiveExpanded((prevComponent) => (prevComponent === component ? undefined : component));
  };

  const handleCampaignNameClose = () => {
    if (!campaignId && !campaignDetails.campaignId) {
      navigate(-1);
    } else {
      setNameOpen(false);
    }
  };

  const handleCampaignNameConfirm = async (name) => {
    setError(false);
    setLoading(true);
    try {
      const { tempId, segmentId, ...data } = campaignDetails;
      if (!data.campaignId) {
        const update = { ...data, campaignId: tempId };
        if (name) update.name = name;
        await axios.post(CAMPAIGNS_URL, update);
      }
      updateCampaignDetails({ campaignId: tempId || data.campaignId, name });
      setNameOpen(false);
    } catch (err) {
      Sentry.captureException(err);
      setError(getNetworkError(err));
    }
    setLoading(false);
  };

  const handleRestoreDraft = async () => {
    setRestoring(true);
    try {
      const originalCampaign = await restoreSuggestedCampaign(campaignDetails.campaignId);
      setConfirmOpen(false);
      resetCampaignTemplate();
      setSuccess('Suggested draft campaign restored successfully!');
      // This operation will cause this component to re-render, so leave it for last
      updateCampaignDetails(originalCampaign);
    } catch (err) {
      Sentry.captureException(err);
      setRestoring(false);
      setError(getNetworkError(err));
    }
  };

  const fromFormComplete = useMemo(() => {
    try {
      fromFormValidationSchema.validateSync(campaignDetails.emailConfig);
      return true;
    } catch (err) {
      return false;
    }
  }, [campaignDetails.emailConfig]);

  const subjectFormComplete = useMemo(() => {
    try {
      subjectFormValidation.validateSync(campaignDetails.emailConfig);
      return true;
    } catch (err) {
      return false;
    }
  }, [campaignDetails.emailConfig]);

  const completed = !!campaignDetails.launched || campaignDetails.sendStatus === CAMPAIGN_SEND_STATUS.sent;

  const sendTimeFormComplete = useMemo(() => {
    if (completed || campaignDetails.type === campaignType.socialMedia) return true;
    if (!campaignDetails.sendTime) return false;
    try {
      timeFormValidation.validateSync({ sendTime: campaignDetails.sendTime });
      return true;
    } catch (error) {
      return false;
    }
  }, [campaignDetails.sendTime, campaignDetails.type, completed]);

  return (
    <>
      <div className="w-full max-w-3xl space-y-4">
        <div className="divide-y divide-gray-50 rounded-xl bg-white-100">
          <OverviewForm key={String(nameOpen)} onRestoreDraft={() => setConfirmOpen(true)} />

          {campaignDetails.type === campaignType.single && (
            <>
              <FormCard
                title={'To'}
                subtitle={'Who is receiving this campaign?'}
                form={loadingTags || loadingSegments ? <Spinner /> : <ToForm disabled={completed} />}
                isExpanded={completed || activeExpanded === expandComponents.to}
                handleClick={() => setExpandedComponent(expandComponents.to)}
                checked={
                  campaignDetails.sendTo.tagIds?.length > 0 ||
                  campaignDetails.sendTo.segmentIds?.length > 0 ||
                  !!campaignDetails.segmentId ||
                  campaignDetails.sendTo?.all
                }
              />

              <FormCard
                title={'From'}
                subtitle={'Who is sending this campaign?'}
                form={<FromForm disabled={completed} />}
                isExpanded={completed || activeExpanded === expandComponents.from}
                handleClick={() => setExpandedComponent(expandComponents.from)}
                checked={fromFormComplete}
              />

              <FormCard
                title={'Subject'}
                subtitle={'What is the subject line?'}
                form={<SubjectForm disabled={completed} />}
                isExpanded={completed || activeExpanded === expandComponents.subject}
                handleClick={() => setExpandedComponent(expandComponents.subject)}
                checked={subjectFormComplete}
              />

              <FormCard
                title={'Send Time'}
                subtitle={'When should the email be sent?'}
                form={<TimeForm disabled={completed} required />}
                isExpanded={completed || activeExpanded === expandComponents.time}
                handleClick={() => setExpandedComponent(expandComponents.time)}
                checked={sendTimeFormComplete}
              />

              <FormCard
                title={'Content'}
                subtitle={'What are you communicating?'}
                form={<ContentFormCard disabled={completed} />}
                isExpanded={completed || activeExpanded === expandComponents.template}
                handleClick={() => setExpandedComponent(expandComponents.template)}
                checked={!!campaignDetails.template.templateId}
              />
            </>
          )}
          {campaignDetails.type === campaignType.socialMedia && (
            <>
              <FormCard
                title={'Networks'}
                subtitle={'Where should this post be shared?'}
                form={<PostNetworksForm disabled={completed} />}
                isExpanded={completed || activeExpanded === expandComponents.postNetworks}
                handleClick={() => setExpandedComponent(expandComponents.postNetworks)}
                checked={!!campaignDetails.socialMediaConfig?.targets?.length}
              />

              <FormCard
                title={'Post Time'}
                subtitle={'When should this be posted?'}
                form={<TimeForm disabled={completed} required />}
                isExpanded={completed || activeExpanded === expandComponents.time}
                handleClick={() => setExpandedComponent(expandComponents.time)}
                checked={sendTimeFormComplete}
              />

              <FormCard
                title={'Post Content'}
                subtitle={'What are you communicating?'}
                form={<PostContentForm disabled={completed} />}
                isExpanded={completed || activeExpanded === expandComponents.postContent}
                handleClick={() => setExpandedComponent(expandComponents.postContent)}
                checked={!!campaignDetails.socialMediaConfig?.content}
              />
            </>
          )}
        </div>

        {campaignDetails.type === campaignType.single && socialMediaPosts && (
          <div className="max-w-3xl divide-y divide-gray-50 rounded-xl bg-white-100">
            <div className="space-y-6 p-8">
              <h3 className="text-h4 font-semibold">Social Media Post</h3>
              <SwitchInput
                label="Schedule a social media post with this email"
                checked={campaignDetails.socialMediaConfig?.enabled ?? false}
                onChange={(checked) => updateCampaignDetails({ socialMediaConfig: { enabled: checked } })}
              />
            </div>

            {campaignDetails.socialMediaConfig?.enabled && (
              <>
                <FormCard
                  title={'Networks'}
                  subtitle={'Where should this post be shared?'}
                  form={<PostNetworksForm disabled={completed} />}
                  isExpanded={completed || activeExpanded === expandComponents.postNetworks}
                  handleClick={() => setExpandedComponent(expandComponents.postNetworks)}
                  checked={!!campaignDetails.socialMediaConfig?.targets?.length}
                />

                <FormCard
                  title={'Post Content'}
                  subtitle={'What are you communicating?'}
                  form={<PostContentForm disabled={completed} />}
                  isExpanded={completed || activeExpanded === expandComponents.postContent}
                  handleClick={() => setExpandedComponent(expandComponents.postContent)}
                  checked={!!campaignDetails.socialMediaConfig?.content}
                />
              </>
            )}
          </div>
        )}
      </div>

      <ChangeCampaignName
        open={nameOpen}
        onClose={handleCampaignNameClose}
        onConfirm={handleCampaignNameConfirm}
        closeDisabled={!campaignDetails.campaignId}
        loading={loading}
      />

      <ConfirmationModal
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={handleRestoreDraft}
        message="All changes to the draft will be lost and it will return to its original suggested state."
        loading={restoring}
      />
    </>
  );
};

export default CampaignForm;
