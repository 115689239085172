import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { useIsMounted } from 'usehooks-ts';
import axios from 'axios';
import isEqual from 'lodash.isequal';

import { CampaignDrawer, InfoRow } from 'ui';
import { getNetworkError } from 'common/utils';
import { CAMPAIGN_SEND_STATUS, CAMPAIGN_TYPES, TENANT_ACCOUNT_INFO_TO_TAG_NAME } from 'common/constants';

import { useCampaigns } from '../../store/campaigns/hooks';
import { useTags } from '../../store/tags/hook';
import { useSegments } from '../../store/segments/hook';
import {
  acceptSuggestedCampaign,
  createTemplate,
  getAudienceSizeCount,
  getDefaultSectionTemplates,
  getTemplate,
  uploadHtml,
} from '../../api';
import { useAccount } from '../../store/account/hooks';
import appSettings from '../../app-settings';
import {
  injectTemplateHtmlSections,
  injectTemplateDesignSections,
  replaceTemplateHtmlDonateButtonStyles,
  replaceTemplateDesignDonateButtonStyles,
} from '../../core/utils';
import { useRecommendedTasks } from '../../store/recommended-tasks/hooks';
import CampaignLabelsInput from '../inputs/CampaignLabelsInput';

const CAMPAIGNS_URL = `${appSettings.baseUrl}/campaigns`;

const URL_REGEX = /https:\/\/(?:[^/]*\.)?supporterfund\.org([a-z0-9=\-&?/]*?(?:campaignId=[a-z0-9]{7}))?/g;

const Drawer = ({ selectedCampaign, selectedCampaignReport }) => {
  const [labelIds, setLabelIds] = useState(selectedCampaign?.labels || []);
  const [template, setTemplate] = useState(null);
  const { duplicateCampaign, setError, getCampaigns, getSuggestedCampaigns, loading, setSuccess } = useCampaigns();
  const { tags, loading: loadingTags } = useTags();
  const { segments, loading: loadingSegments } = useSegments();
  const { account } = useAccount();
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const location = useLocation();
  const { updateReviewDraftTaskStatus } = useRecommendedTasks();

  const isCampaignsPage = location.pathname.includes('emails');
  const isSuggestedCampaign =
    !!selectedCampaign &&
    'sendStatus' in selectedCampaign &&
    selectedCampaign.sendStatus === CAMPAIGN_SEND_STATUS.suggested;
  const isSocialMediaCampaign =
    !!selectedCampaign && 'type' in selectedCampaign && selectedCampaign.type === CAMPAIGN_TYPES.socialMedia;
  const didUpdateLabels = useMemo(
    () => !isEqual(selectedCampaign?.labels || [], labelIds),
    [selectedCampaign?.labels, labelIds],
  );

  useEffect(() => {
    setLabelIds(selectedCampaign?.labels || []);
  }, [selectedCampaign?.labels]);

  const handleError = (error) => {
    Sentry.captureException(error);
  };

  const handleClose = async () => {
    if (didUpdateLabels) {
      await getCampaigns();
    }
    navigate(`${location.pathname}/..`, { replace: true });
  };

  const handleGetTemplate = async (templateId, version, suggested) => {
    /**
     * Replace donation page URLs and organization-level merge tags
     */
    const template = await getTemplate(templateId, version, suggested);
    const donationPageUrl = `https://${account.donationPageSubdomain}.${appSettings.donationPageDomain}/?campaignId=${selectedCampaign.campaignId}`;
    let { design, html } = template;
    design = design.replace(URL_REGEX, donationPageUrl);
    html = html.replace(URL_REGEX, donationPageUrl);
    Object.keys(TENANT_ACCOUNT_INFO_TO_TAG_NAME).forEach((key) => {
      const mergeTag = `{{${TENANT_ACCOUNT_INFO_TO_TAG_NAME[key]}}}`;
      const mergeTagValue = account[key].replaceAll('\n', '<br />'); // Handle multiline values (like address)
      design = design.replaceAll(mergeTag, mergeTagValue);
      html = html.replaceAll(mergeTag, mergeTagValue);
    });

    if (suggested && account.emailPreferences.donateButton) {
      html = replaceTemplateHtmlDonateButtonStyles(html, account.emailPreferences.donateButton);
      design = replaceTemplateDesignDonateButtonStyles(design, account.emailPreferences.donateButton);
    }

    if (suggested && account.emailPreferences.sections) {
      const sectionTemplates = await getDefaultSectionTemplates(account.emailPreferences.sections);

      html = injectTemplateHtmlSections(html, sectionTemplates);
      design = injectTemplateDesignSections(design, sectionTemplates);
    }

    if (isMounted()) setTemplate({ design, html });
    return { design, html };
  };

  const handleViewReport = () => {
    if (!selectedCampaign) return;
    navigate('/reports', { state: { campaignName: selectedCampaign.name } });
  };

  const handleDuplicateCampaign = async () => {
    if (!selectedCampaign) return;
    try {
      await duplicateCampaign(selectedCampaign).unwrap();
      navigate('/new-campaign');
    } catch (error) {
      // Error handled by redux
    }
  };

  const handleAcceptSuggestedCampaign = async () => {
    if (!selectedCampaign) return;
    try {
      const { design, html } = template;
      const { uploadUrl, templateId } = await createTemplate(
        design,
        { type: CAMPAIGN_TYPES.single },
        selectedCampaign.emailConfig,
      );
      await uploadHtml(uploadUrl, html);
      await acceptSuggestedCampaign(selectedCampaign.campaignId, templateId);
      updateReviewDraftTaskStatus(selectedCampaign.campaignId);
      if (isCampaignsPage) {
        await getCampaigns();
      } else {
        await getSuggestedCampaigns();
      }
      setSuccess('Suggested draft campaign successfully accepted!');
      handleClose();
    } catch (err) {
      Sentry.captureException(err);
      setError(getNetworkError(err));
    }
  };

  const handleLabelsChange = async (labels) => {
    try {
      await axios.patch(`${CAMPAIGNS_URL}/${selectedCampaign.campaignId}`, {
        ...selectedCampaign,
        labels,
      });
      setLabelIds(labels);
    } catch (err) {
      Sentry.captureException(err);
      setError(getNetworkError(err));
    }
  };

  const withAudience =
    !!selectedCampaign &&
    (selectedCampaign.sendTo.all ||
      selectedCampaign.sendTo.tagIds.length > 0 ||
      selectedCampaign.sendTo.segmentIds.length > 0);

  const withSender =
    !!selectedCampaign && !!selectedCampaign.emailConfig.senderName && !!selectedCampaign.emailConfig.senderEmail;

  return (
    <CampaignDrawer
      open={!!selectedCampaign}
      onClose={handleClose}
      campaignDetails={selectedCampaign}
      report={selectedCampaignReport}
      tags={tags}
      segments={segments}
      onViewReport={isSocialMediaCampaign ? undefined : handleViewReport}
      onDuplicate={handleDuplicateCampaign}
      loadingAudience={loadingTags || loadingSegments}
      withAudience={withAudience}
      withSender={withSender}
      getAudienceCount={getAudienceSizeCount}
      getTemplate={handleGetTemplate}
      onError={handleError}
      acceptSuggestedCampaign={handleAcceptSuggestedCampaign}
      loading={loading}
    >
      {!isSuggestedCampaign && !isSocialMediaCampaign && (
        <InfoRow
          primaryLabel="Labels"
          value={<CampaignLabelsInput inputLabel="" labelIds={labelIds} onChange={handleLabelsChange} />}
        />
      )}
    </CampaignDrawer>
  );
};

Drawer.propTypes = {
  selectedCampaign: PropTypes.object,
  selectedCampaignReport: PropTypes.object,
};

export default Drawer;
