import { useEffect } from 'react';
import { Dialog } from '@mui/material';
import { IconX, IconAlertCircleFilled } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { useForm, yupResolver } from '@mantine/form';
import * as yup from 'yup';
import { Button, IconButton, Input } from 'ui';

// Match valid GTM ID OR empty string
const formValidation = yup.object({
  gtmId: yup.string().matches(/(^GTM-[A-Z0-9]{8}$|^$)/, 'Format should be GTM-XXXXXXXX where X is a letter or number'),
});

const DonationPageSettingsModal = ({ open, gtmId, loading, onClose, onConfirm }) => {
  const form = useForm({
    initialValues: { gtmId },
    validate: yupResolver(formValidation),
    validateInputOnChange: true,
  });

  useEffect(() => {
    if (typeof gtmId !== 'undefined') form.setFieldValue('gtmId', gtmId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gtmId]);

  const handleConfirm = (values) => {
    onConfirm(values.gtmId);
  };

  const handleClose = () => {
    onClose();
    form.setFieldValue('gtmId', gtmId);
    form.reset();
  };

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      sx={{ '.MuiPaper-root': { padding: 0, margin: 0, borderRadius: '12px', width: '100%' } }}
      closeAfterTransition={false}
    >
      <form noValidate className="space-y-6 p-6" onSubmit={form.onSubmit(handleConfirm)}>
        <div className="relative flex">
          <div className="flex items-center space-x-3">
            <IconAlertCircleFilled size={20} className="text-primary-700" />
            <h3 className="text-h6 font-semibold">Donation Page Settings</h3>
          </div>

          <IconButton
            Icon={<IconX size={20} className="text-gray-400" />}
            srOnly="Close"
            onClick={handleClose}
            color="transparent"
            className="absolute -right-1 -top-1 !p-1"
          />
        </div>

        <p className="text-base">Global settings that apply to all of your donation pages.</p>

        <div className="space-y-4">
          <div className="flex">
            <Input
              {...form.getInputProps('gtmId')}
              label="Google Tag Manager ID"
              placeholder="GTM-XXXXXX"
              size="xl"
              containerClassName="w-full"
              helperText={form.errors.gtmId || 'Enter your GTM ID to enable Google Tag Manager'}
            />
          </div>
        </div>

        <div className="flex justify-end space-x-3">
          <Button title="Cancel" onClick={handleClose} disabled={loading} />

          <Button
            type="submit"
            title={loading ? 'Loading...' : 'Save'}
            color="primary"
            disabled={loading}
            loading={loading}
          />
        </div>
      </form>
    </Dialog>
  );
};

DonationPageSettingsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  gtmId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default DonationPageSettingsModal;
