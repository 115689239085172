import { useCallback, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import { useForm } from '@mantine/form';
import { useIsMounted } from 'usehooks-ts';
import { Link } from 'react-router-dom';

import { Loader, SwitchInput } from 'ui';

import { useCampaigns } from '../../../store/campaigns/hooks';
import { getIntegrations as getIntegrationsApi } from '../../../api';
import { INTEGRATION_FEATURES, INTEGRATION_TYPE_LABELS } from '../../../core/constants';

const PostNetworksForm = ({ disabled }) => {
  const { campaignDetails, updateCampaignDetails } = useCampaigns();
  const [loading, setLoading] = useState(false);
  const [networkAccounts, setNetworkAccounts] = useState([]);
  const form = useForm({
    initialValues: {
      targets: campaignDetails.socialMediaConfig?.targets || [],
    },
  });
  const isMounted = useIsMounted();

  const getIntegrations = useCallback(async () => {
    if (isMounted()) setLoading(true);
    try {
      const integrations = await getIntegrationsApi();
      if (isMounted()) {
        setNetworkAccounts(
          integrations
            .filter((integration) => integration.integrationId.endsWith(INTEGRATION_FEATURES.socialMediaPost))
            .flatMap((integration) =>
              integration.pages.map((page) => ({
                type: integration.type,
                id: page.id,
                name: page.name,
                integrationId: integration.integrationId,
              })),
            ),
        );
      }
    } catch (error) {
      Sentry.captureException(error);
    }
    if (isMounted()) setLoading(false);
  }, [isMounted]);

  useEffect(() => {
    getIntegrations();
  }, [getIntegrations]);

  useEffect(() => {
    updateCampaignDetails({ socialMediaConfig: { targets: form.values.targets } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.targets.length]);

  const handleAccountToggle = (checked, account) => {
    if (checked) {
      form.insertListItem('targets', account);
    } else {
      form.removeListItem(
        'targets',
        form.values.targets.findIndex((target) => target.id === account.id && target.type === account.type),
      );
    }
    // form.values will be stale until next render so we can't update campaign details here
    // update will be handled by the useEffect above
    // after upgrading to Mantine v7 we could use form.getValues()
  };

  return (
    <form className="space-y-6">
      <div className="grid grid-cols-2 gap-4">
        {networkAccounts.map((account) => (
          <SwitchInput
            key={account.id}
            label={
              <span className="flex items-center space-x-2">
                <img
                  src={`/integrations/${account.type}-logo.png`}
                  className="size-[16px]"
                  alt={`${account.type} logo`}
                />
                <span>
                  <span className="font-normal">{INTEGRATION_TYPE_LABELS[account.type]} - </span> {account.name}
                </span>
              </span>
            }
            checked={form.values.targets.some((target) => target.id === account.id && target.type === account.type)}
            onChange={(checked) => handleAccountToggle(checked, account)}
            disabled={disabled}
            className="flex-row-reverse justify-between rounded-lg border border-gray-100 p-2 has-[:checked]:border-primary-700"
          />
        ))}
        {loading && <Loader />}
      </div>

      <p className="text-base">
        Manage connected social networks on the{' '}
        <Link to="/integrations" className="text-primary-700">
          Integrations
        </Link>{' '}
        page
      </p>
    </form>
  );
};

PostNetworksForm.propTypes = {
  disabled: PropTypes.bool,
};

PostNetworksForm.defaultProps = {
  disabled: false,
};

export default PostNetworksForm;
