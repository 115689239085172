import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { IconUpload, IconTrash } from '@tabler/icons-react';
import PropTypes from 'prop-types';

import Button from '../buttons/Button';
import HelperText from './HelperText';
import { IconButton } from 'ui';

const allowedFileTypes = { 'image/jpeg': ['.jpeg', '.jpg'], 'image/png': ['.png'] };

const maxSize = 1024 * 1024 * 5; // 5MB

const errorCodeLabels = {
  'file-too-large': 'File is too large.',
  'too-many-files': 'Please select up to 5 images.',
  'file-invalid-type': 'Invalid file type.',
};

const MAX_FILES = 5;

const ImagesInput = ({ name, onChange, onDelete, errors, setErrors, disabled, imageUrls }) => {
  const handleDelete = (url) => {
    setErrors(undefined);
    onDelete(url);
  };

  const onDrop = useCallback(
    async (acceptedFiles, fileRejections) => {
      setErrors([]);

      if (fileRejections.length > 0) {
        setErrors(
          fileRejections.map(
            (rejection) => `${rejection.file.name} - ${errorCodeLabels[rejection.errors[0]?.code] || 'Invalid file.'}`,
          ),
        );
      }

      if (imageUrls.length + acceptedFiles.length > MAX_FILES) {
        setErrors([errorCodeLabels['too-many-files']]);
        return;
      }
      onChange(acceptedFiles);
    },
    [onChange, setErrors, imageUrls],
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: allowedFileTypes,
    maxSize,
    maxFiles: MAX_FILES,
    onDrop,
    disabled,
  });

  return (
    <div
      className={`flex min-h-36 flex-col items-center justify-center rounded-lg border border-dashed p-4  ${disabled ? 'cursor-not-allowed opacity-80' : 'cursor-pointer'}`}
      {...getRootProps()}
    >
      <input {...getInputProps()} />

      <div className="flex flex-col items-center space-y-3">
        <Button
          title="Upload image(s)"
          size="sm"
          LeftIcon={IconUpload}
          iconProps={{ size: 18 }}
          onClick={open}
          disabled={disabled}
        />
        <p className="text-base text-gray-600">JPEG or PNG, max size 5MB, up to 5 images.</p>
        <div className="flex gap-2">
          {imageUrls?.map((url) => (
            <div key={url} className="relative">
              <img src={url} alt={name} className="max-h-32 rounded-lg" />
              <IconButton
                Icon={<IconTrash size={14} />}
                color="gray"
                className="absolute right-1 top-1 !p-1"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete(url);
                }}
                srOnly="Delete image"
              />
            </div>
          ))}
        </div>
        {errors.map((error, index) => (
          <HelperText key={index} message={error} error />
        ))}
      </div>
    </div>
  );
};

ImagesInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  setErrors: PropTypes.func,
  errors: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  imageUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ImagesInput.defaultProps = {
  setErrors: () => {},
  errors: [],
  disabled: false,
};

export default ImagesInput;
