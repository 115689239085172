import PropTypes from 'prop-types';

import { CONNECTION_STATUS } from 'common/constants';
import { Button, ConnectionStatusLabel, SwitchInput } from 'ui';

import { ReactComponent as StripeLogo } from '../../assets/icons/stripe-logo.svg';
import { usePaymentOptions } from '../../store/payment-options/hooks';
import { useAccount } from '../../store/account/hooks';

const AccountStatus = (props) => {
  const { onboarding, onConnect } = props;
  const { isStripeAccountConnected, isStripeConnectionComplete, stripeAccount, loading, disconnectStripeAccount } =
    usePaymentOptions();
  const { loading: accountLoading, account, setUseStripeNonprofitRates } = useAccount();

  const getConnectionStatus = () => {
    if (isStripeAccountConnected) {
      if (isStripeConnectionComplete) {
        return CONNECTION_STATUS.connected;
      }
      return CONNECTION_STATUS.pending;
    }
    return CONNECTION_STATUS.notConnected;
  };

  return (
    <div
      className={`space-y-8 border border-gray-100 bg-white-100 ${
        onboarding ? 'rounded-lg p-6' : 'max-w-6xl rounded-xl p-8'
      }`}
    >
      {!onboarding && (
        <p className="text-lg">
          Stripe is a leading payment processor used by thousands of major companies and nonprofit organizations. Once
          you connect your Stripe account you will be able to process donations.
        </p>
      )}

      <div className="flex items-center space-x-4">
        <div className="flex flex-1 items-center space-x-6">
          <StripeLogo />

          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <p className="text-h5 font-semibold">Stripe</p>
              <ConnectionStatusLabel status={getConnectionStatus()} />
            </div>

            {isStripeAccountConnected ? (
              <p className="text-base">Account connected {stripeAccount.email}</p>
            ) : (
              <p className="text-base">Connect your account to process payments.</p>
            )}
          </div>
        </div>

        {isStripeAccountConnected && (
          <Button title="Disconnect account" onClick={disconnectStripeAccount} loading={loading} />
        )}

        {!isStripeConnectionComplete && (
          <Button
            title={isStripeAccountConnected ? 'Complete Stripe connection' : 'Connect Stripe account'}
            color="primary"
            onClick={onConnect}
            loading={loading}
          />
        )}
      </div>

      {isStripeAccountConnected && (
        <div className="space-y-4 border-t border-gray-50 pt-8">
          <h3 className="text-h5 font-semibold">Fee discount for nonprofit organizations</h3>
          <p className="text-base">
            Stripe offers discounted payment processing fees (2.2% + $0.30 for non-Amex cards and 3.5% for Amex) for
            approved nonprofit organizations.{' '}
            <a
              href="https://support.stripe.com/questions/fee-discount-for-nonprofit-organizations"
              target="_blank"
              rel="noreferrer"
              className="text-primary-700"
            >
              Learn how to apply.
            </a>
          </p>
          <p className="text-base">
            By default, DonorSpring will use standard Stripe processing fee rates (2.9% + $0.30) when calculating
            transaction totals for donors who choose to <em>Cover Transaction Costs</em>. Enable the setting below to
            use the discounted rates when calculating transaction totals.{' '}
            <strong>
              You should wait until Stripe approves your organization for discounted nonprofit fees before changing this
              setting.
            </strong>
          </p>
          <div className="space-y-1 pt-2">
            <p className="text-base font-medium">
              Use Stripe nonprofit fee rates when calculating donor transaction totals
            </p>
            <SwitchInput
              label={`${account.useStripeNonprofitRates ? 'Enabled' : 'Disabled'}`}
              checked={account.useStripeNonprofitRates}
              onChange={setUseStripeNonprofitRates}
              disabled={accountLoading}
            />
          </div>
        </div>
      )}
    </div>
  );
};

AccountStatus.propTypes = {
  onConnect: PropTypes.func.isRequired,
  onboarding: PropTypes.bool,
};

AccountStatus.defaultProps = {
  onboarding: false,
};

export default AccountStatus;
