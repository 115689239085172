const baseUrls = {
  prod: 'https://api.donorspring.com',
  qa: 'https://api-qa.donorspring.com',
  dev: 'https://api-qa.donorspring.com',
};

const redirectUris = {
  prod: 'https://app.donorspring.com/',
  qa: 'https://app-qa.donorspring.com/',
  dev: 'http://localhost:3000/',
};

const appClients = {
  prod: '5gl8lh82b4ki41hsjrlal1su60',
  qa: '72bkfo1te3kvaspcoe51ip8p4k',
  dev: '72bkfo1te3kvaspcoe51ip8p4k',
};

const authUrls = {
  prod: 'https://auth.donorspring.com',
  qa: 'https://auth-qa.donorspring.com',
  dev: 'https://auth-qa.donorspring.com',
};

const donationPageEditorUrls = {
  prod: 'https://supporterfund.org/editor',
  qa: 'https://qa.supporterfund.org/editor',
  dev: 'https://qa.supporterfund.org/editor',
};

const donationPageDomains = {
  prod: 'supporterfund.org',
  qa: 'qa.supporterfund.org',
  dev: 'qa.supporterfund.org',
};

const emailImagesDomains = {
  prod: 'https://donorspring-email-images-prod.s3.amazonaws.com',
  qa: 'https://donorspring-email-images-qa.s3.amazonaws.com',
  dev: 'https://donorspring-email-images-qa.s3.amazonaws.com',
};

const sentryDsns = {
  prod: 'https://f949aec2e352434db940f1f7d0bcea82@o4504527528329216.ingest.sentry.io/4504571730001920',
  qa: 'https://ab06ab78ad2c46ccb05bd38d408f3335@o4504527528329216.ingest.sentry.io/4504532139311104',
  dev: 'https://ab06ab78ad2c46ccb05bd38d408f3335@o4504527528329216.ingest.sentry.io/4504532139311104',
};

const sentryTracesSampleRates = {
  prod: 0.2, // 20%
  qa: 1.0, // 100%
  dev: 1.0,
};

const sentryReplaysSessionSampleRates = {
  prod: 1.0, // 100% - prod sessions are still relatively low
  qa: 1.0, // 100%
  dev: 0, // set to 1.0 when testing replay
};

const sentryReplaysOnErrorSampleRates = {
  prod: 1.0, // 100%
  qa: 1.0, // 100%
  dev: 0, // set to 1.0 when testing replay
};

const cognitoUserPoolIds = {
  prod: 'us-east-1_k3a6U2brl',
  qa: 'us-east-1_kYomEXV5S',
  dev: 'us-east-1_kYomEXV5S',
};

const cognitoUserClientIds = {
  prod: '5gl8lh82b4ki41hsjrlal1su60',
  qa: '72bkfo1te3kvaspcoe51ip8p4k',
  dev: '72bkfo1te3kvaspcoe51ip8p4k',
};

const stripePublishableKeys = {
  prod: 'pk_live_51LfSSPHyjtoWeR6AhXBD33XQuFUhjxNYqWVmyEMsIA5H9nniWuD2ahxrDsWKXEBf1sE4YrAppDtqe88Og9Re1t4T00oab6LkZN',
  qa: 'pk_test_51LfSSPHyjtoWeR6AsiBPsupFfInmjzlyfowJhJ4mIZwYBm8vURTcwJ7XiEAEsZgMPujrLBzfsv2dvsG11Zru0pun00F43yoNsB',
  dev: 'pk_test_51LfSSPHyjtoWeR6AsiBPsupFfInmjzlyfowJhJ4mIZwYBm8vURTcwJ7XiEAEsZgMPujrLBzfsv2dvsG11Zru0pun00F43yoNsB',
};

const calendlyUrls = {
  prod: 'https://calendly.com/mary-donorspring/donorspring-onboarding',
  qa: 'https://calendly.com/steve-donorspring/30min',
  dev: 'https://calendly.com/steve-donorspring/30min',
};

const launchDarklyClientSideIds = {
  prod: '66b23959b93db6101148a817',
  qa: '66b2412f131a611078d4483f',
  dev: '66b23959b93db6101148a816',
};

const pagesImagesBucketUrls = {
  prod: 'https://donorspring-pages-images-prod.s3.amazonaws.com',
  qa: 'https://donorspring-pages-images-qa.s3.amazonaws.com',
  dev: 'https://donorspring-pages-images-qa.s3.amazonaws.com',
};

const facebookAppClientIds = {
  prod: '632467295893897',
  qa: '632467295893897',
  dev: '632467295893897',
};

const facebookAppConfigIds = {
  prod: '', // TODO add prod config id
  qa: '1617631325534581',
  dev: '1617631325534581',
};

const env = import.meta.env.VITE_ENV ?? 'dev';

const appSettings = {
  redirectUri: redirectUris[env],
  appClient: appClients[env],
  authUrl: authUrls[env],
  baseUrl: baseUrls[env],
  donationPageEditorUrl: donationPageEditorUrls[env],
  donationPageDomain: donationPageDomains[env],
  emailImagesDomain: emailImagesDomains[env],
  sentryDsn: sentryDsns[env],
  sentryTracesSampleRate: sentryTracesSampleRates[env],
  sentryReplaysSessionSampleRate: sentryReplaysSessionSampleRates[env],
  sentryReplaysOnErrorSampleRate: sentryReplaysOnErrorSampleRates[env],
  cognitoUserPoolId: cognitoUserPoolIds[env],
  cognitoUserClientId: cognitoUserClientIds[env],
  stripePublishableKey: stripePublishableKeys[env],
  calendlyUrl: calendlyUrls[env],
  launchDarklyClientSideId: launchDarklyClientSideIds[env],
  pagesImagesBucketUrl: pagesImagesBucketUrls[env],
  facebookAppClientId: facebookAppClientIds[env],
  facebookAppConfigId: facebookAppConfigIds[env],
};

export default appSettings;
