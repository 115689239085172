import { useState } from 'react';
import * as Sentry from '@sentry/react';
import { IconPlus, IconSettings } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { getNetworkError } from 'common/utils';
import { AlertMessage, Button, Loader } from 'ui';

import { setDefaultPage, upsertPage } from '../api';
import { getPageUrl, waitFor } from '../core/utils';
import { STATUS_STATES } from '../core/constants';
import { useAccount } from '../store/account/hooks';
import DonationPageCard from '../components/donation-page/DonationPageCard';
import ConfirmDefaultPageModal from '../components/donation-page/ConfirmDefaultPageModal';
import { usePages } from '../store/pages/hooks';
import DonationPageSettingsModal from '../components/donation-page/DonationPageSettingsModal';

const DonationPages = () => {
  const [newDefault, setNewDefault] = useState(null);
  const [donationPageSettingsOpen, setDonationPageSettingsOpen] = useState(false);
  const { account } = useAccount();
  const navigate = useNavigate();
  const { status, statusDescription, pageList, setStatus, getPages } = usePages();

  const handleSetNewDefault = async (defaultNewSlug) => {
    try {
      setStatus({ status: STATUS_STATES.loading, statusDescription: '' });
      await setDefaultPage({ slug: newDefault.slug, defaultNewSlug });
      // Empiric value - pages need some extra time to reflect changes
      await waitFor(2000);
      await getPages();
      setStatus({
        status: STATUS_STATES.success,
        statusDescription: 'Your default donation page has been successfully updated!',
      });
      setNewDefault(null);
    } catch (error) {
      Sentry.captureException(error);
      setStatus({ status: STATUS_STATES.error, statusDescription: getNetworkError(error) });
    }
  };

  const handleSaveSettings = async (gtmId) => {
    try {
      setStatus({ status: STATUS_STATES.loading, statusDescription: '' });
      await Promise.all(pageList.map((page) => upsertPage({ ...page, content: { ...page.content, gtmId } })));
      // Empiric value - page needs some extra time to reflect changes
      await waitFor(2000);
      await getPages();
      setStatus({
        status: STATUS_STATES.success,
        statusDescription: 'Your settings have been successfully updated!',
      });
      setDonationPageSettingsOpen(false);
    } catch (error) {
      Sentry.captureException(error);
      setStatus({ status: STATUS_STATES.error, statusDescription: getNetworkError(error) });
    }
  };

  const loading = status === STATUS_STATES.loading;
  const error = status === STATUS_STATES.error;
  const success = status === STATUS_STATES.success;

  return (
    <>
      <main className="flex w-full flex-col space-y-6">
        <div className="flex items-center justify-between space-x-3">
          <h1 className="text-h3">Donation Pages</h1>

          <div className="flex items-center space-x-3">
            <Button
              title="Donation Page Settings"
              LeftIcon={IconSettings}
              onClick={() => setDonationPageSettingsOpen(true)}
              disabled={loading}
            />
            <Button
              title="New Donation Page"
              color="primary"
              LeftIcon={IconPlus}
              onClick={() => navigate(`/donation-pages/${pageList.length ? 'new' : 'new-default'}`)}
              disabled={loading}
            />
          </div>
        </div>

        <div className="relative min-h-40 w-full">
          <div className="grid grid-cols-[repeat(auto-fill,minmax(360px,360px))] gap-6">
            {pageList.map((page) => (
              <DonationPageCard
                key={`${page.pageId}-${page.slug}`}
                name={page.name}
                pageUrl={getPageUrl({ account, slug: page.slug })}
                isDefault={page.slug === 'default'}
                onEdit={() => navigate(`/donation-pages/${page.slug}`)}
                onMakeDefault={() => setNewDefault(page)}
              />
            ))}
          </div>

          {loading && <Loader />}
        </div>
      </main>

      <ConfirmDefaultPageModal
        open={!!newDefault}
        name={newDefault?.name || ''}
        defaultName={pageList.find((page) => page.slug === 'default')?.name}
        defaultPageUrl={getPageUrl({ account })}
        loading={loading}
        onClose={() => setNewDefault(null)}
        onConfirm={handleSetNewDefault}
      />

      <DonationPageSettingsModal
        open={donationPageSettingsOpen}
        gtmId={pageList.find((page) => page.slug === 'default')?.content?.gtmId || ''}
        loading={loading}
        onClose={() => setDonationPageSettingsOpen(false)}
        onConfirm={handleSaveSettings}
        disabled={!pageList.length}
      />

      <AlertMessage
        open={error}
        message={statusDescription}
        onClose={() => setStatus({ status: STATUS_STATES.idle })}
        severity="error"
      />

      <AlertMessage
        open={success}
        message={statusDescription}
        onClose={() => setStatus({ status: STATUS_STATES.idle })}
        severity="success"
      />
    </>
  );
};

export default DonationPages;
