import PropTypes from 'prop-types';
import { useSessionStorage } from 'usehooks-ts';
import { nanoid } from 'nanoid';
import { useState } from 'react';

import { CONNECTION_STATUS } from 'common/constants';
import { Button, ConnectionStatusLabel } from 'ui';

import IntegrationCard from './IntegrationCard';
import appSettings from '../../app-settings';

const FacebookCard = ({ onDisconnect, integrations }) => {
  const [, setAuthState] = useSessionStorage('auth-state');
  const [loading, setLoading] = useState(false);

  const getConnectionStatus = () => {
    if (integrations.length) {
      if (
        integrations[0].pages.some((page) => !page.tasks.includes('CREATE_CONTENT') || !page.tasks.includes('ANALYZE'))
      ) {
        return CONNECTION_STATUS.pending;
      }
      return CONNECTION_STATUS.connected;
    }
    return CONNECTION_STATUS.notConnected;
  };

  const pages = getConnectionStatus() !== CONNECTION_STATUS.notConnected ? integrations[0].pages : [];

  const handleConnectFacebook = () => {
    const state = nanoid();
    setAuthState(state);

    window.location.href = `https://www.facebook.com/v22.0/dialog/oauth?${new URLSearchParams({
      client_id: appSettings.facebookAppClientId,
      redirect_uri: `${appSettings.redirectUri}integrations?type=facebook`,
      state,
      config_id: appSettings.facebookAppConfigId,
      response_type: 'code',
      override_default_response_type: 'true',
    })}`;
  };

  const handleDisconnect = async () => {
    setLoading(true);
    try {
      await onDisconnect(integrations[0].integrationId);
    } finally {
      setLoading(false);
    }
  };

  return (
    <IntegrationCard>
      <div className="flex items-center justify-between space-x-6">
        <div className="flex items-center space-x-6">
          <div className="w-[140px]">
            <img src="/integrations/facebook-logo.png" className="m-auto h-auto w-[52px]" alt="Facebook Logo" />
          </div>

          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <h3 className="text-h5 font-semibold">Facebook</h3>

              <ConnectionStatusLabel status={getConnectionStatus()} />
            </div>

            {getConnectionStatus() !== CONNECTION_STATUS.notConnected ? (
              <div>
                <h4 className="text-base font-semibold">Connected pages:</h4>
                <ul className="list-inside list-disc text-base">
                  {pages.map((page) => {
                    const hasPermissions = page.tasks.includes('CREATE_CONTENT') && page.tasks.includes('ANALYZE');

                    return (
                      <li key={page.id}>
                        <span className="mr-2">{page.name}</span>
                        {!hasPermissions && (
                          <ConnectionStatusLabel
                            status={CONNECTION_STATUS.notConnected}
                            label="Missing Permissions"
                            className="inline-block"
                          />
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              <p className="text-base">
                Connect your Facebook account to post to your organization&apos;s Facebook pages.
              </p>
            )}
          </div>
        </div>

        {getConnectionStatus() !== CONNECTION_STATUS.notConnected ? (
          <Button title="Disconnect Facebook Account" color="primary" onClick={handleDisconnect} loading={loading} />
        ) : (
          <Button title="Connect Facebook Account" color="primary" onClick={handleConnectFacebook} />
        )}
      </div>
    </IntegrationCard>
  );
};

FacebookCard.defaultProps = {
  integrations: [],
};

FacebookCard.propTypes = {
  onDisconnect: PropTypes.func.isRequired,
  integrations: PropTypes.arrayOf(PropTypes.object),
};

export default FacebookCard;
