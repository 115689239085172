import { useState, useMemo } from 'react';
import debounce from 'lodash.debounce';
import { IconArrowBackUp } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { Button } from 'ui';

import { useCampaigns } from '../../../store/campaigns/hooks';
import Input from '../../inputs/Input';
import { campaignType, campaignTypeLabels, DEBOUNCE_DELAY_TIME_MS } from '../../../core/constants';
import CampaignLabelsInput from '../../inputs/CampaignLabelsInput';

const OverviewForm = (props) => {
  const { onRestoreDraft } = props;
  const { campaignDetails, updateCampaignDetails } = useCampaigns();
  const [name, setName] = useState(campaignDetails.name);

  const debouncedUpdateCampaignDetails = useMemo(
    () => debounce(updateCampaignDetails, DEBOUNCE_DELAY_TIME_MS),
    [updateCampaignDetails],
  );

  const handleNameChange = (event) => {
    const value = event.target.value;
    setName(value);
    debouncedUpdateCampaignDetails({ name: value });
  };

  const handleLabelsChange = (labels) => {
    updateCampaignDetails({ labels });
  };

  return (
    <div className="space-y-6 p-8">
      <div className="flex items-center justify-between">
        <h3 className="text-h4 font-semibold">{campaignTypeLabels[campaignDetails.type]} Overview</h3>

        {!!campaignDetails.suggestedDraftId && (
          <Button
            title="Restore Suggested Draft"
            size="sm"
            color="destructive"
            variant="outlined"
            LeftIcon={IconArrowBackUp}
            onClick={onRestoreDraft}
          />
        )}
      </div>

      <Input
        label={`${campaignTypeLabels[campaignDetails.type]} Name`}
        value={name}
        onChange={handleNameChange}
        placeholder={`${campaignTypeLabels[campaignDetails.type]} Name`}
        required
        size="xl"
        error={!campaignDetails.name}
        helperText={!campaignDetails.name ? 'Campaign name is required' : ''}
      />

      {campaignDetails.type === campaignType.single && (
        <>
          <div className="space-y-1">
            <p className="text-base font-semibold">Organize your campaigns with labels</p>
          </div>
          <CampaignLabelsInput labelIds={campaignDetails.labels} onChange={handleLabelsChange} />
        </>
      )}
    </div>
  );
};

OverviewForm.propTypes = {
  onRestoreDraft: PropTypes.func.isRequired,
};

export default OverviewForm;
