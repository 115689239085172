import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import isArray from 'lodash.isarray';
import mergeWith from 'lodash.mergewith';

import { nanoid } from '../../core/utils';
import {
  setCampaignTemplate,
  updateCampaignDetails,
  setSendTo,
  resetCampaignDetails,
  resetCampaignTemplate,
  setLoading,
  setError,
  setSuccess,
  getCampaigns,
  getCampaign,
  setViewTrashCan,
  softDeleteCampaign,
  cancelCampaign,
  restoreCampaign,
  restoreCampaigns,
  hardDeleteCampaigns,
  duplicateCampaign,
  setViewMode,
  setCalendarDate,
  setCalendarView,
  getSuggestedCampaigns,
  getLabels,
  createLabel,
} from './actions';
import { CAMPAIGNS_VIEW_MODES, campaignType } from '../../core/constants';

const cutomizedMerge = (objValue, srcValue) => {
  if (srcValue === '' || isArray(objValue)) {
    return srcValue;
  }
};

export const initialCampaignDetails = {
  campaignId: '',
  tempId: nanoid(),
  emailConfig: {
    senderName: '',
    senderEmail: '',
    subject: '',
    returnEmail: '',
    previewText: '',
  },
  name: 'Untitled',
  sendStatus: 'draft',
  sendTime: '',
  sendTo: {
    all: false,
    tagIds: [],
    segmentIds: [],
  },
  template: {
    templateId: '',
    version: '',
  },
  launched: false,
  segmentId: '', // left for backwards compatibility - deprecated
  exclude: {
    tagIds: [],
    segmentIds: [],
  },
  socialMediaConfig: {
    content: '',
    imageUrls: [],
    targets: [],
  },
};

export const initialCampaignTemplate = {
  templateName: '',
  templateId: '',
  templateVersion: '',
  createTimestamp: '',
  uploadStatus: '',
  notes: '',
  design: '',
  html: '',
};

const initialState = {
  campaignDetails: initialCampaignDetails,
  campaignTemplate: initialCampaignTemplate,
  loading: false,
  error: false,
  success: false,
  campaigns: [],
  socialMediaPosts: [],
  viewTrashCan: false,
  viewMode: CAMPAIGNS_VIEW_MODES.table,
  calendarDate: new Date().toISOString(),
  calendarView: 'month',
  suggestedCampaigns: [],
  labels: [],
};

const campaignsStore = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setCampaignTemplate, (state, { payload }) => {
      state.campaignTemplate = { ...initialCampaignTemplate, ...payload };
      state.campaignDetails.template.templateId = payload.templateId || '';
      state.campaignDetails.template.version = payload.templateVersion || '';
    });

    builder.addCase(updateCampaignDetails, (state, { payload }) => {
      state.campaignDetails = mergeWith({}, state.campaignDetails, payload, cutomizedMerge);
    });

    builder.addCase(setSendTo, (state, { payload }) => {
      if (payload.tagIds) {
        state.campaignDetails.sendTo.tagIds = payload.tagIds;
      }
      state.campaignDetails.sendTo.all = !!payload.all;
    });

    builder.addCase(resetCampaignDetails, (state) => {
      state.campaignDetails = { ...initialCampaignDetails, tempId: nanoid() };
    });

    builder.addCase(resetCampaignTemplate, (state) => {
      state.campaignTemplate = initialCampaignTemplate;
    });

    builder.addCase(setLoading, (state, { payload }) => {
      state.loading = payload;
    });

    builder.addCase(setError, (state, { payload }) => {
      state.error = payload;
    });

    builder.addCase(setSuccess, (state, { payload }) => {
      state.success = payload;
    });

    builder.addCase(setViewTrashCan, (state, { payload }) => {
      state.viewTrashCan = payload;
    });

    builder.addCase(setViewMode, (state, { payload }) => {
      state.viewMode = payload;
    });

    builder.addCase(setCalendarDate, (state, { payload }) => {
      state.calendarDate = payload;
    });

    builder.addCase(setCalendarView, (state, { payload }) => {
      state.calendarView = payload;
    });

    builder.addCase(getCampaigns.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.campaigns = payload.filter((campaign) => campaign.type === campaignType.single);
      state.socialMediaPosts = payload.filter((campaign) => campaign.type === campaignType.socialMedia);
    });

    builder.addCase(getCampaign.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.campaignDetails = { ...payload, tempId: '' };
    });

    builder.addCase(softDeleteCampaign.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.success = 'Campaign successfully deleted!';
      state.campaigns = payload.campaigns;
      state.suggestedCampaigns = payload.suggestedCampaigns;
    });

    builder.addCase(cancelCampaign.fulfilled, (state) => {
      state.loading = false;
      state.success = 'Campaign successfully cancelled!';
    });

    builder.addCase(restoreCampaign.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.success = 'Campaign successfully restored!';
      state.campaigns = payload;
    });

    builder.addCase(restoreCampaigns.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.success = 'Campaigns successfully restored!';
      state.campaigns = payload;
    });

    builder.addCase(hardDeleteCampaigns.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.success = 'Campaigns permanently deleted!';
      state.campaigns = payload;
    });

    builder.addCase(duplicateCampaign.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.campaignDetails = mergeWith({}, state.campaignDetails, payload, cutomizedMerge);
    });

    builder.addCase(getSuggestedCampaigns.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.suggestedCampaigns = payload;
    });

    builder.addCase(getCampaign.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message || true;
      state.campaignDetails = initialCampaignDetails;
      state.campaignTemplate = initialCampaignTemplate;
    });

    builder.addCase(getLabels.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.labels = payload;
    });

    builder.addCase(createLabel.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.labels.push(payload);
    });

    builder.addMatcher(
      isAnyOf(
        getCampaigns.rejected,
        softDeleteCampaign.rejected,
        cancelCampaign.rejected,
        restoreCampaign.rejected,
        restoreCampaigns.rejected,
        hardDeleteCampaigns.rejected,
        duplicateCampaign.rejected,
        getSuggestedCampaigns.rejected,
        getLabels.rejected,
      ),
      (state, { error }) => {
        state.loading = false;
        state.error = error.message || true;
      },
    );

    builder.addMatcher(
      isAnyOf(
        getCampaigns.pending,
        getCampaign.pending,
        softDeleteCampaign.pending,
        cancelCampaign.pending,
        restoreCampaign.pending,
        restoreCampaigns.pending,
        hardDeleteCampaigns.pending,
        duplicateCampaign.pending,
        getSuggestedCampaigns.pending,
        getLabels.pending,
      ),
      (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
      },
    );
  },
});

export default campaignsStore.reducer;
