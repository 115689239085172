import {
  IconCategory,
  IconDownload,
  IconFileImport,
  IconFileSettings,
  IconMail,
  IconMailCog,
  IconNetwork,
  IconNotes,
  IconReplace,
  IconReport,
  IconReportMoney,
  IconTag,
  IconUserCog,
  IconUsers,
  IconUsersPlus,
  IconWallet,
  IconSettingsDollar,
  IconSend,
  IconCircleCheck,
  IconArrowsExchange,
  IconMailPlus,
  IconLayoutNavbar,
} from '@tabler/icons-react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useAccount } from '../../store/account/hooks';
import NavbarListItem from './NavbarListItem';
import { USER_ROLES } from '../../core/constants';

const Navbar = () => {
  const { account } = useAccount();
  const { socialMediaPosts } = useFlags();

  return (
    <div className="sticky left-0 top-0 w-64 shrink-0 overflow-y-auto bg-white-100 px-2 py-4" data-testid="navbar">
      <ul className="pl-3 text-sm font-semibold uppercase text-gray-600">
        <li>
          <ul className="my-2">
            <NavbarListItem to="/" Icon={IconFileSettings} title="Dashboard" />
            <NavbarListItem to="donations" Icon={IconReportMoney} title="Donations" />
            <NavbarListItem to="recommended-tasks" Icon={IconCircleCheck} title="Recommended Tasks" />
          </ul>
        </li>
        <li>
          Contacts
          <ul className="my-2">
            <NavbarListItem to="contacts" Icon={IconUsers} title="All Contacts" />
            <NavbarListItem to="segments" Icon={IconCategory} title="Segments" />
            <NavbarListItem to="tags" Icon={IconTag} title="Tags" />
            <NavbarListItem to="imports" Icon={IconFileImport} title="Imports" />
          </ul>
        </li>
        <li>
          Campaigns
          <ul className="my-2">
            <NavbarListItem to="emails" Icon={IconMail} title="Email Campaigns" />
            {socialMediaPosts && (
              <NavbarListItem to="social-media" Icon={IconLayoutNavbar} title="Social Media Posts" />
            )}
            <NavbarListItem to="suggested-drafts" Icon={IconSend} title="Suggested Drafts" />
            <NavbarListItem to="reports" Icon={IconReport} title="Reports" />
            <NavbarListItem to="recurring" Icon={IconReplace} title="Recurring" />
            <NavbarListItem to="transactional" Icon={IconFileSettings} title="Transactional" />
            <NavbarListItem to="email-settings" Icon={IconMailCog} title="Email Settings" />
            <NavbarListItem to="exports" Icon={IconDownload} title="Exports" />
          </ul>
        </li>
        <li>
          Settings
          <ul className="my-2">
            <NavbarListItem to="donation-pages" Icon={IconNotes} title="Donation Pages" />
            <NavbarListItem to="payment-options" Icon={IconWallet} title="Payment Options" />
            <NavbarListItem to="account-info" Icon={IconUserCog} title="Account Info" />
            <NavbarListItem to="domain-auth" Icon={IconNetwork} title="Domain Authentication" />
            <NavbarListItem to="integrations" Icon={IconArrowsExchange} title="Integrations" />
            <NavbarListItem to="opt-in-page" Icon={IconMailPlus} title="Opt-In Page" />
          </ul>
        </li>
        {account.userRole === USER_ROLES.administrator && (
          <li>
            Billing
            <ul className="my-2">
              {!!account.subscription && (
                <NavbarListItem to="subscription" Icon={IconSettingsDollar} title="Subscription" />
              )}
              <NavbarListItem to="user-seats" Icon={IconUsersPlus} title="User Seats" />
            </ul>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Navbar;
