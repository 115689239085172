import PropTypes from 'prop-types';

const IntegrationStatus = (props) => {
  const { status } = props;
  if (status === 'pending') {
    return (
      <div className="w-fit rounded border border-orange-200 bg-orange-50 px-1.5 py-0.5">
        <p className="text-caption1 font-semibold uppercase text-orange-700">Coming Soon</p>
      </div>
    );
  }
  return (
    <div className="w-fit rounded border border-green-100 bg-green-50 px-1.5 py-0.5">
      <p className="text-caption1 font-semibold uppercase text-green-500">Available</p>
    </div>
  );
};

IntegrationStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default IntegrationStatus;
