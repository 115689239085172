import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { IconArrowLeft } from '@tabler/icons-react';

import appSettings from '../app-settings';
import AlertMessage from '../components/alerts/AlertMessage';
import { getNetworkError, waitFor } from '../core/utils';
import { useCampaigns } from '../store/campaigns/hooks';
import {
  CAMPAIGNS_VIEW_MODES,
  PREVIEW_HTML_KEY,
  campaignSendStatus,
  campaignType,
  campaignTypeLabels,
} from '../core/constants';
import Loader from '../components/loader/Loader';
import { useDomains } from '../store/domains/hooks';
import ConfirmLaunchModal from '../components/newcampaign/ConfirmLaunchModal';
import IconButton from '../components/buttons/IconButton';
import Button from '../components/buttons/Button';
import CampaignForm from '../components/newcampaign/CampaignForm';
import { validateCampaign } from '../components/newcampaign/utils';
import { useRecommendedTasks } from '../store/recommended-tasks/hooks';

const CAMPAIGNS_URL = `${appSettings.baseUrl}/campaigns`;

const DONATION_PAGE_URL_REGEX = /https(.*?)campaignId=([a-z0-9]{7})/g;

const SUGGESTED_PROMPT_REGEX = /\[\[(.*?)\]\]/g;

const NewCampaign = () => {
  const [loading, setLoading] = useState(false);
  const [launchModalOpen, setLaunchModalOpen] = useState(false);
  const [confirmLaunchMessage, setConfirmLaunchMessage] = useState('');
  const {
    campaignDetails,
    updateCampaignDetails,
    error,
    setError,
    campaignTemplate,
    getCampaign,
    loading: campaignLoading,
    viewMode,
    setCalendarDate,
    success,
    setSuccess,
    getLabels,
  } = useCampaigns();
  const { getDomains } = useDomains();
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const { updateScheduleCampaignTaskStatus } = useRecommendedTasks();

  useEffect(() => {
    return () => {
      setSuccess(false);
    };
  }, [setSuccess]);

  useEffect(() => {
    return () => {
      // We want to preserve the error when campaignId param is defined
      if (!campaignId) setError(false);
    };
  }, [setError, campaignId]);

  useEffect(() => {
    getDomains();
  }, [getDomains]);

  useEffect(() => {
    getLabels();
  }, [getLabels]);

  const getCampaignDetails = useCallback(async () => {
    try {
      await getCampaign(campaignId).unwrap();
    } catch (error) {
      // Campaign not found or any other network error
      navigate('/emails', { replace: true });
    }
  }, [getCampaign, campaignId, navigate]);

  useEffect(() => {
    if (campaignId) getCampaignDetails();
  }, [campaignId, getCampaignDetails]);

  const launchError = campaignDetails.sendStatus === campaignSendStatus.error;

  useEffect(() => {
    if (launchError) {
      setError('Something went wrong when launching your campaign, please try again!');
    }
  }, [launchError, setError]);

  const updateCalendarViewDate = (sendTime) => {
    if (!sendTime || viewMode !== CAMPAIGNS_VIEW_MODES.calendar) return;
    setCalendarDate(sendTime);
  };

  const saveCampaign = async () => {
    const errorMessage = validateCampaign({ campaign: campaignDetails });

    if (errorMessage) {
      setError(errorMessage);
      return;
    }

    setError(false);
    setLoading(true);
    try {
      // segmentId has been deprecated, do not send as payload
      const { tempId, segmentId, ...data } = campaignDetails;
      if (data.campaignId) {
        await axios.patch(`${CAMPAIGNS_URL}/${data.campaignId}`, data);
        updateCalendarViewDate(data.sendTime);
        navigate(campaignDetails.type === campaignType.single ? '/emails' : '/social-media', { replace: true });
      } else {
        await axios.post(CAMPAIGNS_URL, { ...data, campaignId: tempId });
        updateCampaignDetails({ campaignId: tempId });
        updateCalendarViewDate(data.sendTime);
        setLoading(false);
      }
    } catch (err) {
      Sentry.captureException(err);
      setError(getNetworkError(err));
      setLoading(false);
    }
  };

  const launchCampaign = async () => {
    setLaunchModalOpen(false);
    setError(false);
    setLoading(true);
    try {
      const localTimeInMs = new Date(campaignDetails.sendTime).getTime() - new Date().getTimezoneOffset() * 60 * 1000;
      const localSendTime = new Date(localTimeInMs).toISOString();
      await axios.post(`${CAMPAIGNS_URL}/launch`, { ...campaignDetails, localSendTime });
      updateCampaignDetails({ launched: true });
      setSuccess('Campaign launched successfully.');
      updateCalendarViewDate(campaignDetails.sendTime);
      updateScheduleCampaignTaskStatus({ sendTime: campaignDetails.sendTime, delta: 1 });
      await waitFor(1500);
      navigate(campaignDetails.type === campaignType.single ? '/emails' : '/social-media', { replace: true });
    } catch (err) {
      Sentry.captureException(err);
      setError(getNetworkError(err));
    }
    setLoading(false);
  };

  const onLaunchCampaign = () => {
    const errorMessage = validateCampaign({ campaign: campaignDetails, launch: true });

    if (errorMessage) {
      setError(errorMessage);
      return;
    }

    if (campaignTemplate.html) {
      if (campaignTemplate.html.search(DONATION_PAGE_URL_REGEX) === -1) {
        // Email template does not contain a single donation page url
        setConfirmLaunchMessage(
          'It looks like there are no donate buttons in this email, are you sure you want to send without one?',
        );
      } else if (campaignTemplate.html.search(SUGGESTED_PROMPT_REGEX) !== -1) {
        // Email template contains a suggested prompt
        setConfirmLaunchMessage(
          'It looks like there are still some text prompts (denoted by double brackets [[]]) that should be removed from this email. Are you sure you want to send?',
        );
      }
    }

    setLaunchModalOpen(true);
  };

  const previewCampaign = () => {
    localStorage.setItem(PREVIEW_HTML_KEY, campaignTemplate.html);
    window.open('/template-preview', '_blank');
  };

  const completed = !!campaignDetails.launched || campaignDetails.sendStatus === campaignSendStatus.sent;

  if (campaignId && campaignLoading) {
    return <Loader />;
  }

  return (
    <div className="w-full space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-1">
          <IconButton
            Icon={<IconArrowLeft size={20} />}
            color="transparent"
            className="-ml-2"
            onClick={() => navigate(-1)}
            srOnly="Go back to campaigns"
          />

          <h1 className="text-h3">
            {campaignId
              ? `${completed ? 'View' : 'Edit'} ${campaignTypeLabels[campaignDetails.type]}`
              : `Create New ${campaignTypeLabels[campaignDetails.type]}`}
          </h1>
        </div>

        <div className="flex items-center space-x-3">
          {!completed && (
            <>
              <Button title={campaignDetails.campaignId ? 'Save & Exit' : 'Save'} onClick={saveCampaign} />

              <Button
                title={campaignType.single === campaignDetails.type ? 'Launch Campaign' : 'Schedule Post'}
                color="primary"
                onClick={onLaunchCampaign}
                disabled={!campaignDetails.campaignId}
              />
            </>
          )}
          {campaignDetails.type === campaignType.single && (
            <Button title="Preview" onClick={previewCampaign} disabled={!campaignTemplate.html} />
          )}
        </div>
      </div>

      <div className="relative flex justify-center">
        <CampaignForm key={campaignDetails.updatedAt} />

        {loading && <Loader />}
      </div>

      <ConfirmLaunchModal
        open={launchModalOpen}
        onClose={() => setLaunchModalOpen(false)}
        onConfirm={launchCampaign}
        message={confirmLaunchMessage}
      />

      <AlertMessage
        open={!!success}
        message={typeof success === 'string' ? success : 'Operation completed successfully!'}
        onClose={() => setSuccess(false)}
        severity="success"
      />

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        onClose={() => setError(false)}
        severity="error"
        autoHideDuration={launchError ? null : 6000}
      />
    </div>
  );
};

export default NewCampaign;
