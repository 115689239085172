import { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IconArrowLeft, IconTrash, IconArrowBack, IconPlus, IconCheck, IconX } from '@tabler/icons-react';
import axios from 'axios';
import * as Sentry from '@sentry/react';

import Table from '../components/emails/Table';
import AlertMessage from '../components/alerts/AlertMessage';
import { useCampaigns } from '../store/campaigns/hooks';
import {
  campaignSendStatus,
  campaignSortBy,
  sortDirections,
  campaignSendStatusLabels,
  campaignSortByLabels,
  campaignType,
  reportTypes,
  SOCIAL_INTEGRATION_TYPES,
} from '../core/constants';
import { sortCampaigns } from '../core/utils';
import Loader from '../components/loader/Loader';
import useInterval from '../hooks/useInterval';
import Button from '../components/buttons/Button';
import Filters from '../components/emails/Filters';
import Chip from '../components/chip/Chip';
import IconButton from '../components/buttons/IconButton';
import ConfirmDeleteModal from '../components/emails/ConfirmDeleteModal';
import Drawer from '../components/emails/Drawer';
import SegmentControl from '../components/segment-control/SegmentControl';
import Sorters from '../components/tables/Sorters';
import appSettings from '../app-settings';

const REPORTS_URL = `${appSettings.baseUrl}/reports`;

const POLLING_INTERVAL_IN_MS = 15000;

const AUDIENCE_FILTER_SEGMENTS = [
  { segment: 'included', Icon: IconCheck, label: 'In Audience' },
  { segment: 'not_included', Icon: IconX, label: 'Not In Audience' },
];

const sortByOptions = [
  { label: campaignSortByLabels[campaignSortBy.sendStatus], value: campaignSortBy.sendStatus },
  { label: campaignSortByLabels[campaignSortBy.updatedAt], value: campaignSortBy.updatedAt },
  { label: campaignSortByLabels[campaignSortBy.name], value: campaignSortBy.name },
];

const audienceIncludesSegments = (post, segmentFilters) =>
  segmentFilters.some(
    (segment) =>
      (post.sendTo.all && !post.exclude?.segmentIds?.includes(segment.segmentId)) ||
      post.sendTo.segmentIds?.includes(segment.segmentId) ||
      post.sendTo.segment === segment.segmentId,
  );

const audienceIncludesTags = (post, tagFilters) =>
  tagFilters.some(
    (tag) => (post.sendTo.all && !post.exclude?.tagIds?.includes(tag.id)) || post.sendTo.tagIds?.includes(tag.id),
  );

const SocialMedia = () => {
  const [sortBy, setSortBy] = useState(campaignSortBy.updatedAt);
  const [sortDirection, setSortDirection] = useState(sortDirections.desc);
  const [sendStatusFilters, setSendStatusFilters] = useState([]);
  const [labelFilters, setLabelFilters] = useState([]);
  const [segmentFilters, setSegmentFilters] = useState([]);
  const [tagFilters, setTagFilters] = useState([]);
  const [audienceFilterMatch, setAudienceFilterMatch] = useState('included');
  const [campaignErrors, setCampaignErrors] = useState([]);
  const [selectedRows, setSelectedRows] = useState(0);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [loadingReports, setLoadingReports] = useState(true);
  const [reports, setReports] = useState([]);
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const { selectedPostId } = useParams();
  const {
    error,
    success,
    setError,
    setSuccess,
    resetCampaignDetails,
    resetCampaignTemplate,
    updateCampaignDetails,
    getCampaigns: getCampaignsAction,
    viewTrashCan,
    socialMediaPosts,
    loading,
    setViewTrashCan,
    restoreCampaigns,
    hardDeleteCampaigns,
  } = useCampaigns();

  const getReports = useCallback(async () => {
    setLoadingReports(true);
    try {
      const res = await axios.get(REPORTS_URL, { params: { type: reportTypes.socialMedia } });
      setReports(res.data);
    } catch (err) {
      Sentry.captureException(err);
      setError(err?.message || true);
    }
    setLoadingReports(false);
  }, [setError]);

  const getCampaigns = useCallback(async () => {
    try {
      const data = await getCampaignsAction().unwrap();
      if (viewTrashCan) return;
      setCampaignErrors(data.filter((item) => item.sendStatus === campaignSendStatus.error && !item.launched));
    } catch (err) {
      // Error handled by redux
    }
  }, [getCampaignsAction, viewTrashCan]);

  useEffect(() => {
    getCampaigns();
    getReports();
  }, [getCampaigns, getReports]);

  const selectedPost = socialMediaPosts.find((post) => post.campaignId === selectedPostId);
  const selectedPostReport = reports.find((report) => report.campaignId === selectedPostId);

  useEffect(() => {
    resetCampaignDetails();
    resetCampaignTemplate();
  }, [resetCampaignDetails, resetCampaignTemplate]);

  const isLaunching = useMemo(() => {
    return socialMediaPosts.some(
      ({ launched, sendStatus, sendTime }) =>
        launched &&
        ([campaignSendStatus.draft, campaignSendStatus.error].includes(sendStatus) ||
          (sendStatus === campaignSendStatus.scheduled && new Date().toISOString() >= sendTime)),
    );
  }, [socialMediaPosts]);

  useInterval(
    () => {
      getCampaigns();
      getReports();
    },
    isLaunching ? POLLING_INTERVAL_IN_MS : null,
  );

  const goToNewPost = () => {
    resetCampaignDetails();
    resetCampaignTemplate();
    updateCampaignDetails({ type: campaignType.socialMedia, socialMediaConfig: { enabled: true } });
    navigate('/new-post');
  };

  const goToEditPost = (post) => {
    if (!post) return;
    resetCampaignTemplate();
    updateCampaignDetails(post);
    navigate(`/edit-post/${post.campaignId}`);
  };

  const goBackToPosts = () => {
    setViewTrashCan(false);
    getCampaigns();
  };

  const handleHardDeletePosts = async () => {
    await hardDeleteCampaigns(selectedRows.map((row) => row.campaignId));
    tableRef.current?.resetSelectedRows();
    setDeleteOpen(false);
  };

  const handleRestorePosts = async () => {
    await restoreCampaigns(selectedRows.map((row) => row.campaignId));
    tableRef.current?.resetSelectedRows();
  };

  const resetFilters = () => {
    setSendStatusFilters([]);
    setLabelFilters([]);
    setSegmentFilters([]);
    setTagFilters([]);
  };

  const data = useMemo(() => {
    let filteredCampaigns = socialMediaPosts.map((post) => {
      if (post.sendStatus !== campaignSendStatus.sent) {
        return post;
      }

      let report = reports.find((r) => r.campaignId === post.campaignId);
      if (!report) {
        return post;
      }

      report.totalImpressions = SOCIAL_INTEGRATION_TYPES.map((type) => report[type]?.impressions).reduce(
        (total, curr) => total + curr,
        0,
      );
      report.totalInteractions = SOCIAL_INTEGRATION_TYPES.reduce((total, type) => {
        const typeReport = report[type];
        if (!typeReport) return total;
        const typeTotal = Object.keys(typeReport).reduce((typeTotal, key) => {
          if (key === 'impressions') return typeTotal;
          return typeTotal + typeReport[key];
        }, 0);
        return total + typeTotal;
      }, 0);

      return { ...post, report };
    });

    if (sendStatusFilters.length > 0) {
      filteredCampaigns = filteredCampaigns.filter((campaign) => sendStatusFilters.includes(campaign.sendStatus));
    }
    if (labelFilters.length > 0) {
      filteredCampaigns = filteredCampaigns.filter((campaign) =>
        labelFilters.some((label) => campaign.labels?.includes(label.labelId)),
      );
    }
    if (segmentFilters.length > 0) {
      filteredCampaigns = filteredCampaigns.filter((campaign) =>
        audienceFilterMatch === 'included'
          ? audienceIncludesSegments(campaign, segmentFilters)
          : !audienceIncludesSegments(campaign, segmentFilters),
      );
    }
    if (tagFilters.length > 0) {
      filteredCampaigns = filteredCampaigns.filter((campaign) =>
        audienceFilterMatch === 'included'
          ? audienceIncludesTags(campaign, tagFilters)
          : !audienceIncludesTags(campaign, tagFilters),
      );
    }
    if (sortBy) {
      filteredCampaigns = sortCampaigns([...filteredCampaigns], sortBy, sortDirection);
    }
    return filteredCampaigns;
  }, [
    socialMediaPosts,
    sendStatusFilters,
    sortBy,
    sortDirection,
    labelFilters,
    segmentFilters,
    tagFilters,
    audienceFilterMatch,
    reports,
  ]);

  const campaignError = campaignErrors[0];

  const selectedRowsCount = selectedRows.length;

  return (
    <div className="w-full space-y-6">
      <div className="flex justify-between">
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center space-x-1">
            {viewTrashCan && (
              <IconButton
                Icon={<IconArrowLeft size={20} />}
                color="transparent"
                className="-ml-2"
                onClick={goBackToPosts}
                srOnly="Go back to posts"
              />
            )}
            <h1 className="text-h3">
              {viewTrashCan
                ? selectedRowsCount
                  ? `${selectedRowsCount} selected`
                  : 'Social Media Posts Trash Can'
                : 'Social Media Posts'}
            </h1>
          </div>

          {viewTrashCan ? (
            <div className="flex items-center space-x-3">
              {selectedRowsCount > 0 && (
                <Button title="Restore selected" LeftIcon={IconArrowBack} onClick={handleRestorePosts} />
              )}

              <Button
                title={selectedRowsCount ? 'Delete selected' : 'Delete all'}
                color="destructive"
                variant="outlined"
                LeftIcon={IconTrash}
                onClick={() => setDeleteOpen(true)}
                disabled={!data.length}
              />
            </div>
          ) : (
            <div className="flex items-center space-x-3">
              <Sorters
                options={sortByOptions}
                sortBy={sortBy}
                setSortBy={setSortBy}
                sortDirection={sortDirection}
                setSortDirection={setSortDirection}
              />

              <Filters
                key={[
                  ...sendStatusFilters,
                  ...labelFilters.map(({ labelId }) => labelId),
                  ...segmentFilters.map(({ segmentId }) => segmentId),
                  ...tagFilters.map(({ id }) => id),
                ].join(',')}
                sendStatusFilters={sendStatusFilters}
                setSendStatusFilters={setSendStatusFilters}
                labelFilters={labelFilters}
                setLabelFilters={setLabelFilters}
                segmentFilters={segmentFilters}
                setSegmentFilters={setSegmentFilters}
                tagFilters={tagFilters}
                setTagFilters={setTagFilters}
              />

              <Button title="New Post" color="primary" LeftIcon={IconPlus} onClick={goToNewPost} />
            </div>
          )}
        </div>
      </div>

      {(!!sendStatusFilters.length || !!labelFilters.length) && (
        <div className="flex flex-wrap items-center space-x-2">
          {sendStatusFilters.map((sendStatus) => (
            <Chip
              key={`chip-${sendStatus}`}
              label={campaignSendStatusLabels[sendStatus]}
              onDelete={() => setSendStatusFilters((prev) => prev.filter((s) => s !== sendStatus))}
              className="border-0 bg-white-100 px-[10px] py-[5px] !text-gray-800"
              iconClassName="text-gray-400"
            />
          ))}

          {labelFilters.map((label) => (
            <Chip
              key={`chip-${label.labelId}`}
              label={label.name}
              onDelete={() => setLabelFilters((prev) => prev.filter((l) => l.labelId !== label.labelId))}
              className="border-0 bg-white-100 px-[10px] py-[5px] !text-gray-800"
              iconClassName="text-gray-400"
            />
          ))}

          <button className="px-[10px] py-[5px] text-sm text-gray-600" onClick={() => resetFilters()}>
            Reset all
          </button>
        </div>
      )}

      {(!!segmentFilters.length || !!tagFilters.length) && (
        <div className="flex flex-wrap items-center space-x-2">
          <SegmentControl
            segments={AUDIENCE_FILTER_SEGMENTS}
            selectedSegment={audienceFilterMatch}
            onSelect={setAudienceFilterMatch}
            segmentWidth={160}
            segmentHeight={32}
            buttonSize="sm"
            containerClassName="bg-gray-50"
          />

          {segmentFilters.map((segment) => (
            <Chip
              key={`chip-${segment.segmentId}`}
              label={segment.name}
              onDelete={() => setSegmentFilters((prev) => prev.filter((s) => s.segmentId !== segment.segmentId))}
              className="border-0 bg-white-100 px-[10px] py-[5px] !text-gray-800"
              iconClassName="text-gray-400"
            />
          ))}

          {tagFilters.map((tag) => (
            <Chip
              key={`chip-${tag.id}`}
              label={tag.name}
              onDelete={() => setTagFilters((prev) => prev.filter((t) => t.id !== tag.id))}
              className="border-0 bg-white-100 px-[10px] py-[5px] !text-gray-800"
              iconClassName="text-gray-400"
            />
          ))}
          <button className="px-[10px] py-[5px] text-sm text-gray-600" onClick={() => resetFilters()}>
            Reset all
          </button>
        </div>
      )}

      <div className="relative flex-1 overflow-x-auto overflow-y-hidden">
        <Table
          key={`posts-${viewTrashCan}`}
          data={data}
          setSelectedRows={setSelectedRows}
          ref={tableRef}
          type={campaignType.socialMedia}
        />

        {(loading || loadingReports) && <Loader />}
      </div>

      <ConfirmDeleteModal
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onConfirm={handleHardDeletePosts}
        title="Delete selected posts?"
        description="Are you sure you want to delete the selected posts? This action cannot be undone."
        loading={loading}
      />

      <Drawer selectedCampaign={selectedPost} selectedCampaignReport={selectedPostReport} />

      <AlertMessage
        open={!!success}
        message={typeof success === 'string' ? success : 'Operation completed successfully!'}
        onClose={() => setSuccess(false)}
        severity="success"
      />

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        onClose={() => setError(false)}
        severity="error"
      />

      <AlertMessage
        open={!!campaignError}
        message={
          <>
            <span>Launch error for post </span>
            <span className="cursor-pointer underline" onClick={() => goToEditPost(campaignError)}>
              {campaignError?.name}
            </span>
          </>
        }
        onClose={() => setCampaignErrors((prevState) => prevState.slice(1))}
        severity="error"
      />
    </div>
  );
};

export default SocialMedia;
