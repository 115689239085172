import { useRef, useEffect, useCallback, useState } from 'react';
import { IconExternalLink } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import postRobot from 'post-robot';
import * as Sentry from '@sentry/react';
import mergeWith from 'lodash.mergewith';
import { STATUS_STATES } from '../../core/constants';

import Button from '../buttons/Button';
import { useAccount } from '../../store/account/hooks';
import appSettings from '../../app-settings';
import PageEditor from '../page-editor/PageEditor';
import StepNavBottom from './StepNavBottom';
import AlertMessage from '../alerts/AlertMessage';
import { getNetworkError, getPageUrl, waitFor } from '../../core/utils';
import { getPage, upsertPage } from '../../api';

const DonationPageStep = ({ onPrev, onNext }) => {
  const editorRef = useRef(null);
  const { account, getAccount } = useAccount();
  const [status, setStatus] = useState({ state: STATUS_STATES.idle, message: '' });
  const [pageData, setPageData] = useState(null);

  const slug = 'default';

  const getPageData = useCallback(async () => {
    try {
      setStatus({ state: STATUS_STATES.loading, message: '' });
      const data = await getPage(slug);
      setPageData(data || null);
      setStatus({
        state: STATUS_STATES.idle,
        message: '',
      });
    } catch (error) {
      Sentry.captureException(error);
      setStatus({ state: STATUS_STATES.error, message: getNetworkError(error) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  useEffect(() => {
    // Makes sure we always have the latest page state changes.
    // Use case: deployment failed, but page state was saved, and user goes back and forth on the onboarding steps.
    getPageData();
  }, [getPageData]);

  const pageUrl = getPageUrl({ account, slug });

  const handleEditorLoad = async () => {
    try {
      const baseUrl = pageUrl.replace('slug', '');

      const content = pageData?.content
        ? {
            ...pageData.content,
            metadata: { name: pageData.name || '', slug, baseUrl },
          }
        : { metadata: { baseUrl, name: 'Default Donation Page', slug } };

      const initialState = account?.logoUrl
        ? mergeWith({}, { images: { headerImage: { url: account.logoUrl } } }, content)
        : content;

      await postRobot.send(editorRef.current.contentWindow, 'initEditor', { initialState, isOnboarding: true });
    } catch (error) {
      Sentry.captureException(error);
      setStatus({ state: STATUS_STATES.error, message: 'Something went wrong while initializing the editor.' });
    }
  };

  const handleSave = async () => {
    try {
      if (!account) return;

      const response = await postRobot.send(editorRef.current.contentWindow, 'saveAndExit');

      if (!response.data) return;

      setStatus({ state: STATUS_STATES.loading, message: '' });

      const pageSlug = slug || response.data.metadata.slug;

      await upsertPage({
        tenantId: account.tenantId,
        slug: pageSlug,
        content: response.data,
        action: pageData ? 'update' : 'create',
      });

      if (!account.donationPageSubdomain) getAccount();

      // Empiric value - page needs some extra time to reflect changes
      await waitFor(2000);

      // Re-fetch page state for reflecting updated state on the editor
      await getPageData();
      setStatus({ state: STATUS_STATES.success, message: 'Donation page launched successfully!' });
    } catch (err) {
      Sentry.captureException(err);
      setStatus({ state: STATUS_STATES.error, message: getNetworkError(err) });
    }
  };

  const loading = status.state === STATUS_STATES.loading;
  const error = status.state === STATUS_STATES.error;
  const success = status.state === STATUS_STATES.success;

  return (
    <div className="m-auto flex h-full max-w-5xl flex-col space-y-2 px-4 pb-4">
      <div className="space-y-4">
        <div className="flex justify-between space-x-3">
          <h1 className="text-h1 font-semibold">Set up a donation page</h1>

          <div className="mt-1 flex items-center space-x-2">
            <Button title="Save" color="primary" onClick={handleSave} size="sm" />

            <Button
              title="Preview"
              href={pageUrl}
              target="_blank"
              rel="noreferrer"
              LeftIcon={IconExternalLink}
              size="sm"
              disabled={!account?.donationPageSubdomain}
            />
          </div>
        </div>
        <p className="text-lg">Create your first donation page.</p>
      </div>

      <div className="flex-1 min-[1472px]:-ml-10">
        <PageEditor
          ref={editorRef}
          pageUrl={`${appSettings.donationPageEditorUrl}/?customerSupportEmail=${account?.customerSupportEmail}`}
          loading={loading}
          onLoad={handleEditorLoad}
        />
      </div>

      <StepNavBottom
        onPrev={onPrev}
        onNext={onNext}
        prevDisabled={loading}
        nextDisabled={!account?.donationPageSubdomain}
      />

      <AlertMessage
        open={success}
        message={status.message}
        onClose={() => setStatus({ state: STATUS_STATES.idle, message: '' })}
        severity="success"
      />

      <AlertMessage
        open={error}
        message={status.message}
        onClose={() => setStatus({ state: STATUS_STATES.idle, message: '' })}
        severity="error"
      />
    </div>
  );
};

DonationPageStep.propTypes = {
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default DonationPageStep;
