import { useState, useEffect, useCallback } from 'react';
import * as Sentry from '@sentry/react';
import { useSearchParams } from 'react-router-dom';
import { useSessionStorage } from 'usehooks-ts';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { AlertMessage, Loader } from 'ui';
import { getNetworkError } from 'common/utils';

import {
  createIntegration as createIntegrationApi,
  deleteIntegration,
  getIntegrations as getIntegrationsApi,
} from '../api';
import ZapierCard from '../components/integrations/ZapierCard';
import FacebookCard from '../components/integrations/FacebookCard';
import appSettings from '../app-settings';

const Integrations = () => {
  const [integrationsByType, setIntegrationsByType] = useState({});
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [connecting, setConnecting] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [savedAuthState] = useSessionStorage('auth-state');
  const { socialMediaPosts } = useFlags();

  const getIntegrations = useCallback(async () => {
    setLoading(true);
    try {
      const integrations = await getIntegrationsApi();
      setIntegrationsByType(
        integrations.reduce((acc, integration) => {
          if (!acc[integration.type]) {
            acc[integration.type] = [];
          }
          acc[integration.type].push(integration);
          return acc;
        }, {}),
      );
    } catch (error) {
      Sentry.captureException(error);
      setError(getNetworkError(error));
    }
    setLoading(false);
  }, []);

  const createIntegration = useCallback(
    async (code, type) => {
      setLoading(true);
      setConnecting(true);
      try {
        await createIntegrationApi({
          code,
          type,
          redirectUri: `${appSettings.redirectUri}integrations?type=${type}`,
        });
        setSuccess('Integration connected successfully!');
        setConnecting(false);
        getIntegrations();
      } catch (err) {
        Sentry.captureException(err);
        setError(getNetworkError(err));
        setLoading(false);
        setConnecting(false);
      }
    },
    [getIntegrations],
  );

  useEffect(() => {
    try {
      const authError = searchParams.get('error');
      const code = searchParams.get('code');
      const state = searchParams.get('state');
      const type = searchParams.get('type');

      if (authError) {
        throw new Error(authError);
      }

      if (!code || !state || !savedAuthState || !type) return;

      if (state !== savedAuthState) {
        throw new Error('Invalid state');
      }

      createIntegration(code, type);
    } catch (e) {
      setError(`Failed to connect integration - ${e.message}`);
    } finally {
      setSearchParams({});
    }
  }, [createIntegration, savedAuthState, searchParams, setSearchParams]);

  useEffect(() => {
    getIntegrations();
  }, [getIntegrations]);

  const handleDisconnect = async (integrationId) => {
    try {
      await deleteIntegration(integrationId);
      setSuccess('Integration disconnected successfully!');
      getIntegrations();
    } catch (err) {
      Sentry.captureException(err);
      setError(getNetworkError(err));
    }
  };

  return (
    <>
      <div className="w-full space-y-6">
        <h1 className="text-h3">Integrations</h1>

        <div className="relative space-y-6">
          {loading || connecting ? (
            <Loader />
          ) : (
            <>
              <ZapierCard integrations={integrationsByType.zapier} />
              {socialMediaPosts && (
                <FacebookCard integrations={integrationsByType.facebook} onDisconnect={handleDisconnect} />
              )}
            </>
          )}
        </div>
      </div>

      <AlertMessage
        open={!!success}
        message={typeof success === 'string' ? success : 'Operation completed successfully!'}
        onClose={() => setSuccess(false)}
        severity="success"
      />

      <AlertMessage open={!!error} message={error} onClose={() => setError('')} severity="error" />
    </>
  );
};

export default Integrations;
