/* eslint-disable react/prop-types */
import { useMemo, useCallback, useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useReactTable, getCoreRowModel, getPaginationRowModel, flexRender } from '@tanstack/react-table';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { CampaignStatusLabel } from 'ui';
import { CAMPAIGN_SEND_STATUS } from 'common/constants';
import { formatDistanceToNowStrict } from 'date-fns';

import { campaignSendStatus, CAMPAIGN_ACTIONS, campaignType } from '../../core/constants';
import { formatDate } from '../../core/utils';
import { useCampaigns } from '../../store/campaigns/hooks';
import CustomPagination from '../tables/CustomPagination';
import Spinner from '../loader/Spinner';
import Checkbox from '../inputs/Checkbox';
import RowActions from '../tables/RowActions';
import CampaignLabels from './CampaignLabels';

const IndeterminateCheckbox = ({ indeterminate, className = '', ...rest }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return <Checkbox ref={ref} inputClassName={className} {...rest} />;
};

const getCampaignActionOptions = (sendStatus, deleted = false) => {
  if (deleted) return [CAMPAIGN_ACTIONS.restore];
  switch (sendStatus) {
    case CAMPAIGN_SEND_STATUS.draft:
      return [CAMPAIGN_ACTIONS.edit, CAMPAIGN_ACTIONS.duplicate, CAMPAIGN_ACTIONS.delete];
    case CAMPAIGN_SEND_STATUS.error:
      return [CAMPAIGN_ACTIONS.edit, CAMPAIGN_ACTIONS.delete];
    case CAMPAIGN_SEND_STATUS.scheduled:
      return [CAMPAIGN_ACTIONS.view, CAMPAIGN_ACTIONS.duplicate, CAMPAIGN_ACTIONS.cancel];
    case CAMPAIGN_SEND_STATUS.sending:
      return [CAMPAIGN_ACTIONS.view, CAMPAIGN_ACTIONS.duplicate];
    case CAMPAIGN_SEND_STATUS.sent:
      return [CAMPAIGN_ACTIONS.view, CAMPAIGN_ACTIONS.viewReport, CAMPAIGN_ACTIONS.duplicate];
    case CAMPAIGN_SEND_STATUS.suggested:
      return [CAMPAIGN_ACTIONS.view];
    default:
      return [];
  }
};

const Table = forwardRef(({ data, setSelectedRows, type = campaignType.single }, ref) => {
  const [rowSelection, setRowSelection] = useState({});
  const navigate = useNavigate();
  const {
    updateCampaignDetails,
    resetCampaignTemplate,
    viewTrashCan,
    softDeleteCampaign,
    cancelCampaign,
    restoreCampaign,
    duplicateCampaign,
  } = useCampaigns();

  const onSelectAction = useCallback(
    async (email, action) => {
      switch (action) {
        case CAMPAIGN_ACTIONS.view: {
          navigate(email.campaignId);
          break;
        }
        case CAMPAIGN_ACTIONS.edit: {
          resetCampaignTemplate();
          updateCampaignDetails(email);
          navigate(`/edit-campaign/${email.campaignId}`);
          break;
        }
        case CAMPAIGN_ACTIONS.viewReport: {
          navigate('/reports', { state: { campaignName: email.name } });
          break;
        }
        case CAMPAIGN_ACTIONS.duplicate: {
          try {
            await duplicateCampaign(email).unwrap();
            navigate('/new-campaign');
          } catch (error) {
            // Error handled by redux
          }
          break;
        }
        case CAMPAIGN_ACTIONS.delete: {
          softDeleteCampaign(email.campaignId);
          break;
        }
        case CAMPAIGN_ACTIONS.cancel: {
          cancelCampaign(email);
          break;
        }
        case CAMPAIGN_ACTIONS.restore: {
          restoreCampaign(email.campaignId);
          break;
        }
        default:
          break;
      }
    },
    [
      navigate,
      resetCampaignTemplate,
      updateCampaignDetails,
      duplicateCampaign,
      softDeleteCampaign,
      cancelCampaign,
      restoreCampaign,
    ],
  );

  const columns = useMemo(
    () => [
      ...(viewTrashCan
        ? [
            {
              id: 'select',
              header: ({ table }) => (
                <IndeterminateCheckbox
                  {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler(),
                  }}
                />
              ),
              cell: ({ row }) => (
                <IndeterminateCheckbox
                  {...{
                    checked: row.getIsSelected(),
                    indeterminate: row.getIsSomeSelected(),
                    onChange: row.getToggleSelectedHandler(),
                  }}
                />
              ),
            },
          ]
        : []),
      {
        accessorKey: 'name',
        header: () => 'Name',
        footer: (props) => props.column.id,
        cell: (info) => <span className="font-medium text-gray-900">{info.getValue()}</span>,
        minSize: 240,
      },
      {
        accessorKey: 'updatedAt',
        header: () => 'Last Edited',
        footer: (props) => props.column.id,
        cell: (info) => {
          const updatedAt = info.getValue();
          return updatedAt ? (
            <span title={formatDate(new Date(updatedAt * 1000))}>
              {formatDistanceToNowStrict(new Date(updatedAt * 1000), { addSuffix: true })}
            </span>
          ) : (
            ''
          );
        },
        size: 140,
        maxSize: 140,
      },
      {
        accessorKey: 'sendStatus',
        header: () => 'Stage',
        footer: (props) => props.column.id,
        cell: (info) => {
          const sendStatus = info.getValue();
          const inProgress =
            info.row.original.launched &&
            ([campaignSendStatus.draft, campaignSendStatus.error].includes(sendStatus) ||
              (sendStatus === campaignSendStatus.scheduled && new Date().toISOString() >= info.row.original.sendTime));
          return inProgress ? <Spinner /> : <CampaignStatusLabel status={sendStatus} />;
        },
        size: 115,
        maxSize: 155,
      },
      {
        accessorKey: 'sendTime',
        header: () => (type === campaignType.single ? 'Send time' : 'Post time'),
        footer: (props) => props.column.id,
        cell: (info) => {
          const sendTime = info.getValue();
          return sendTime ? formatDate(sendTime) : '';
        },
        size: 190,
        maxSize: 190,
      },
      ...(type === campaignType.single
        ? [
            {
              accessorKey: 'labels',
              header: () => 'Labels',
              footer: (props) => props.column.id,
              cell: (info) => (
                <CampaignLabels
                  labelIds={info.getValue()}
                  key={`labels-${info.row.original.campaignId}-${(info.getValue() ?? []).join(',')}`}
                />
              ),
              size: 260,
              minSize: 260,
              maxSize: 260,
            },
          ]
        : [
            {
              id: 'report.totalImpressions',
              accessorFn: (row) => row.report?.totalImpressions,
              header: () => 'Impressions',
              footer: (props) => props.column.id,
              cell: (info) => (
                <span>{info.row.original.sendStatus === campaignSendStatus.sent ? info.getValue() || 0 : ''}</span>
              ),
              size: 120,
              maxSize: 120,
            },
            {
              id: 'report.totalInteractions',
              accessorFn: (row) => row.report?.totalInteractions,
              header: () => 'Interactions',
              footer: (props) => props.column.id,
              cell: (info) => (
                <span>{info.row.original.sendStatus === campaignSendStatus.sent ? info.getValue() || 0 : ''}</span>
              ),
              size: 125,
              maxSize: 125,
            },
          ]),
      {
        accessorKey: 'campaignId',
        header: () => '',
        footer: (props) => props.column.id,
        cell: (info) => (
          <RowActions
            actions={getCampaignActionOptions(info.row.original.sendStatus, info.row.original.deleted)}
            onSelectAction={(action) => onSelectAction(info.row.original, action)}
          />
        ),
        size: 104,
        minSize: 104,
      },
    ],
    [onSelectAction, type, viewTrashCan],
  );

  const table = useReactTable({
    data,
    columns,
    pageCount: data.totalPages,
    state: {
      rowSelection,
    },
    defaultColumn: {
      size: 'fit-content',
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onRowSelectionChange: setRowSelection,
  });

  useImperativeHandle(
    ref,
    () => ({
      resetSelectedRows: () => table.resetRowSelection(),
    }),
    [table],
  );

  const selectedRows = useMemo(() => {
    return table.getSelectedRowModel().flatRows.map((fR) => fR.original);
  }, [table]);

  useEffect(() => {
    if (setSelectedRows) setSelectedRows(selectedRows);
  }, [selectedRows, setSelectedRows]);

  const emptyRows = 5 - data.length;

  return (
    <div className="space-y-6">
      <div className="relative overflow-x-auto rounded-xl">
        {/* Table begins */}
        <table className="w-full text-left text-base text-gray-950">
          <thead className="bg-white-100 text-sm uppercase text-gray-400">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className="px-4 py-3"
                      style={{
                        width: header.column.columnDef.size,
                        minWidth: header.column.columnDef.minSize,
                        maxWidth: header.column.columnDef.maxSize || 'auto',
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              const isSelected = row.getIsSelected();
              return (
                <tr
                  key={row.id}
                  className={`h-[52px] border-b border-gray-50 hover:bg-gray-10 ${
                    isSelected ? 'bg-primary-50' : 'bg-white-100'
                  }`}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className="px-4 py-2"
                        style={{
                          width: cell.column.columnDef.size,
                          minWidth: cell.column.columnDef.minSize,
                          maxWidth: cell.column.columnDef.maxSize || 'auto',
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {emptyRows > 0 &&
              Array(emptyRows)
                .fill('')
                .map((row, index) => (
                  <tr
                    key={`emptyRow-${index}`}
                    className="h-[52px] border-b border-gray-50 bg-white-100 hover:bg-gray-10"
                  >
                    <td className="px-4 py-2" colSpan={15} style={{ minWidth: 110 }}></td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>

      <CustomPagination table={table} total={data.length} />
    </div>
  );
});

Table.displayName = 'Table';

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSelectedRows: PropTypes.func,
};

export default Table;
