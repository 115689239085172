import { campaignSendStatus, campaignType, campaignTypeLabels } from '../../core/constants';
import { fromFormValidationSchema } from './Forms/FromForm';
import { timeFormValidation } from './Forms/TimeForm';
import { subjectFormValidation } from './Forms/SubjectForm';

export const validateCampaign = ({ campaign, launch = false }) => {
  const { name, sendStatus, sendTime, sendTo, template, emailConfig, type } = campaign;
  try {
    if (!name) {
      return `${campaignTypeLabels[type]} name is required.`;
    }
    if (type === campaignType.socialMedia) {
      return null;
    }
    if (launch || sendTime) {
      timeFormValidation.validateSync({ sendTime: sendTime ? new Date(sendTime) : new Date() });
    }
    if (launch) {
      if (![campaignSendStatus.draft, campaignSendStatus.error].includes(sendStatus)) {
        return `You can't launch campaigns on "${sendStatus}" send status.`;
      }
      const isAudienceSelected = !!(sendTo?.all || sendTo?.tagIds?.length || sendTo?.segmentIds?.length);
      if (!isAudienceSelected) {
        return 'Please select a campaign audience.';
      }
    }
    if (launch || emailConfig.senderName) {
      fromFormValidationSchema.validateSyncAt('senderName', emailConfig);
    }
    if (launch || emailConfig.senderEmail) {
      fromFormValidationSchema.validateSyncAt('senderEmail', emailConfig);
    }
    if (launch || emailConfig.subject) {
      subjectFormValidation.validateSyncAt('subject', emailConfig);
    }
    if (launch || emailConfig.previewText) {
      subjectFormValidation.validateSyncAt('previewText', emailConfig);
    }
    if (launch && (!template || !template.templateId || !template.version)) {
      return 'Please select a campaign Template.';
    }
    return null;
  } catch (error) {
    return error.message ?? null;
  }
};
