import PropTypes from 'prop-types';

const IntegrationCard = ({ children }) => {
  return <div className="max-w-5xl space-y-4 rounded-xl bg-white-100 p-8 shadow-md">{children}</div>;
};

IntegrationCard.propTypes = {
  children: PropTypes.node,
};

export default IntegrationCard;
