import { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconArrowRight, IconArrowLeft } from '@tabler/icons-react';
import axios from 'axios';
import * as Sentry from '@sentry/react';

import TemplatesSection from '../components/email-templates/templates-section';
import { templateTypes } from '../core/constants';
import appSettings from '../app-settings';
import Loader from '../components/loader/Loader';
import { useEmailTemplates } from '../store/email-templates/hooks';
import Button from '../components/buttons/Button';
import IconButton from '../components/buttons/IconButton';

const initialTemplates = {
  basicTemplates: [
    {
      title: 'Blank',
      image: 'blank.svg',
      source: 'blank.json',
      type: templateTypes.single,
    },
    {
      title: 'Letter',
      image: 'letter.svg',
      source: 'letter.json',
      type: templateTypes.single,
    },
    {
      title: 'Announcement',
      image: 'announcement.svg',
      source: 'announcement.json',
      type: templateTypes.single,
    },
    {
      title: 'Multi-Button Contribution',
      image: 'multi-button-contribution.svg',
      source: 'multi-button-contribution.json',
      type: templateTypes.single,
    },
  ],
  recurringTemplates: [
    {
      title: 'Welcome Email #1',
      image: 'blank.svg',
      source: 'blank.json',
      type: templateTypes.recurring,
      order: 0,
    },
    {
      title: 'Welcome Email #2',
      image: 'blank.svg',
      source: 'blank.json',
      type: templateTypes.recurring,
      order: 1,
    },
    {
      title: 'Welcome Email #3',
      image: 'blank.svg',
      source: 'blank.json',
      type: templateTypes.recurring,
      order: 2,
    },
    {
      title: 'Welcome Email #4',
      image: 'letter.svg',
      source: 'welcome-4.json',
      type: templateTypes.recurring,
      order: 3,
    },
  ],
};
const EMAIL_TEMPLATES_URL = `${appSettings.baseUrl}/email-templates`;

const EmailTemplates = () => {
  const [templates, setTemplates] = useState(initialTemplates);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { resetTemplate, updateTemplate } = useEmailTemplates();

  const getTemplates = useCallback(async () => {
    if (location.state?.type !== templateTypes.recurring) {
      setLoading(false);
      return;
    }
    try {
      const { data: savedTemplates } = await axios.get(EMAIL_TEMPLATES_URL, {
        params: { type: templateTypes.recurring },
      });

      // Merge saved templates with initial templates
      savedTemplates.forEach(({ design, downloadUrl, order, templateId, templateVersion, emailConfig, type }) => {
        templates.recurringTemplates[order] = {
          ...templates.recurringTemplates[order],
          design,
          downloadUrl,
          templateId,
          templateVersion,
          emailConfig,
          order,
          type,
        };
      });

      setTemplates(templates);
    } catch (err) {
      Sentry.captureException(err);
    }
    setLoading(false);
  }, [location.state?.type, templates]);

  useEffect(() => {
    getTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSelectedTemplateAndContinue = () => {
    resetTemplate();
    updateTemplate(selectedTemplate);
    navigate(location.state?.type === templateTypes.recurring ? '/email-config' : '/email-editor', {
      state: { selectedTemplate },
    });
  };

  const handleGoBack = () => {
    navigate(location.state?.type === templateTypes.recurring ? '/recurring' : '/new-campaign', {
      replace: true,
    });
  };

  return (
    <div className="relative w-full space-y-6">
      <div className="flex items-center space-x-1">
        <IconButton
          Icon={<IconArrowLeft size={20} />}
          color="transparent"
          className="-ml-2"
          onClick={handleGoBack}
          srOnly="Go back"
        />

        <h1 className="text-h3">
          {location.state?.type === templateTypes.recurring ? 'Select an Email' : 'Select a Template'}
        </h1>
      </div>

      <div className="relative min-h-[50px] w-full">
        {!loading && (
          <TemplatesSection
            key="Basic"
            title="Basic"
            templates={
              location.state?.type === templateTypes.recurring ? templates.recurringTemplates : templates.basicTemplates
            }
            selectTemplate={(t) => setSelectedTemplate(t)}
            selectedTemplate={selectedTemplate}
          />
        )}

        {loading && <Loader />}
      </div>

      <div className="flex justify-end">
        <Button
          title="Continue"
          onClick={setSelectedTemplateAndContinue}
          disabled={!selectedTemplate}
          RightIcon={IconArrowRight}
        />
      </div>
    </div>
  );
};

export default EmailTemplates;
